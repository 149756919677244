<template>
  <div class="insertion-vis-container">
    <div id="buttons">
      <button @click="insertionSort">Insertion Sort</button>
      <button @click="stop = true" style="margin-left:40px">Stop</button>
      <button @click="reset">Reset</button>
    </div>

    <div id="insertion-vis"></div>

    <div>Steps: <span id="insertion-counter">0</span></div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  data() {
    return {
      durationTime: 0,

      array: [],
      unsortedArray: [],
      sortedArray: [],
      count: 0,

      stop: false,
      steps: 0,

      margin: { top: 40, right: 0, bottom: 0, left: 0 },
      width: 0,
      height: 0,
      barWidth: 0,

      x: null,
      svg: null,
      rects: null,
      labels: null
    }
  },


  props: ['n' , 'stepDuration'],

  mounted() {
    this.count = Number(this.n) + 1;
    this.array = d3.shuffle(d3.range(1, this.count))
    this.durationTime = Number(this.stepDuration);
    this.unsortedArray = [...this.array];
    this.sortedArray = [];


    this.width = 700 - this.margin.left - this.margin.right;
    this.height = 300 - this.margin.top - this.margin.bottom;
    this.barWidth = this.width / this.count;

    this.x = d3.scaleLinear()
      .domain([0, this.count])
      .range([0, this.width]);

    this.svg = d3.select("#insertion-vis").append("svg")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .append("g")
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    this.rects = this.svg.append("g")
      .attr("transform", "translate(" + this.barWidth + ",2)")
      .selectAll("rect")
      .data(this.unsortedArray)
      .enter().append("rect");

    this.labels = this.svg.selectAll("text")
      .data(this.unsortedArray)
      .enter().append("text");


    this.labels.attr("id", function (d) { return "insertion-text" + d })
      .attr('x', (d, i) => { return this.x(i) })
      .attr('y', () => { return 0 })
      .html(function (d) { return d; });

    this.rects.attr("id", function (d) { return "insertion-rect" + d })
      .attr("width", this.barWidth * .9)
      .attr("height", (d) => { return (d * this.barWidth / 3) })
      .attr('x', (d, i) => { return this.x(i) - this.barWidth })
      .attr('y', (d) => { return this.height - (d * this.barWidth / 3) })
  },


  methods: {
    async reset() {
      this.stop = true;

      await d3.timeout(() => {
        // Reset arrays
        this.unsortedArray = [...this.array];
        this.sortedArray = [];
        this.stop = false;

        d3.select("#insertion-counter").html(this.steps = 0) // Reset step counter

        this.labels.attr("class", "")
          .classed("testing", false)
          .classed("sorted", false)
          .transition().duration(2000)
          .attr("x", (d, i) => { return this.x(i); })

        this.rects.attr("class", "")
          .transition().duration(2000)
          .attr("x", (d, i) => { return this.x(i - 1); })

      }, this.durationTime * 2);


    },


    insertionSort() {
      let value = this.unsortedArray.shift();
      this.sortedArray.push(value);
      reArrange(this.sortedArray.length - 1, this);

      function reArrange(n, that) {
        if (that.stop) { return that.stop = false; }

        //d3.selectAll("rect").attr("class", "")
        d3.select("#insertion-rect" + value).attr("class", "sorted")
        d3.select("#insertion-text" + value).attr("class", "sorted")
        d3.select("#insertion-counter").html(++that.steps);

        if (n > 0 && that.sortedArray[n - 1] > value) {
          d3.select("#insertion-rect" + value).attr("class", "testing")
          d3.select("#insertion-text" + value).attr("class", "testing")
          
          d3.timeout(function () {
            that.sortedArray.splice(n, 1);
            that.sortedArray.splice(n - 1, 0, value);

            that.slide(that.sortedArray[n], n);
            that.slide(that.sortedArray[n - 1], n - 1);

            reArrange(--n, that)
          }, that.durationTime);
          
        } else if (that.unsortedArray.length) {
          d3.timeout(function () { that.insertionSort() }, that.durationTime);
        }
      }
    },

    // Slides the text and rect of d to the position of index i 
    slide(d, i) {
      d3.select("#insertion-text" + d)
        .transition().duration(this.durationTime)
        .attr("x", () => { return this.x(i); })

      d3.select("#insertion-rect" + d)
        .transition().duration(this.durationTime)
        .attr("x", () => { return this.x(i - 1); })
    }


  }

}
</script>


<style scoped>

#buttons > button {
  background-color: darkgreen;
  border-radius: 5px;
  color: white;
  margin-right: 10px;
}

.insertion-vis-container {
  margin: 20px;
  margin-left: 20px;
}

#insertion-vis {
  margin: 10px 0;
}

#insertion-vis :deep(text) {
  fill: black;
}

#insertion-vis :deep(rect) {
  fill: darkgreen;
}

#insertion-vis :deep(.sorted) {
  fill: #999;
}

#insertion-vis :deep(.min) {
  fill: red;
}

#insertion-vis :deep(.testing) {
  fill: orange;
}
</style>