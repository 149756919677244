<template>
  <div class="card-frame" :class="{ cardFixed: (fixActive && !showSwapConfirmation) }">
    <div v-if="showSwapConfirmation" class="swap-confirmation-dialog">
      <div class="swap-confirmation-header">
        Karte 1
        <br />
        <i class="bi bi-arrow-left-right"></i>
        <br />
        Karte 2
      </div>
      <div class="swap-confirmation-buttons">
        <div class="confirmation-btn-accept-small">
          <i class="bi bi-check-lg"></i>
        </div>
        <div class="confirmation-btn-cancel-small">
          <i class="bi bi-x-lg"></i>
        </div>
      </div>
    </div>
    <div v-else class="card-content">
      <div class="fixieren-container unselectable" @click="fixActive = !fixActive">
        FIX
      </div>
      <div class="card-number"></div>
      <div class="btn-container" :class="{ btnContainerNotVisible: !showBtnContainer }">
        <div class="btn unselectable">
          <div>
            <i class="bi bi-eye-slash" style="font-size: 1.6em"></i>
          </div>
        </div>
        <div class="btn unselectable">
          <div>
            <i class="bi bi-pin-angle" style="font-size: 1.6em"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["showSwapConfirmation", "showBtnContainer"],
  data() {
    return {
      fixActive: false,
    };
  },
};
</script>

<style scoped>
.card-frame {
  border-radius: 10px;
  border: 2px solid black;
  height: 250px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

/** SWAP CONFIRMATION - FOR INDIVIDUAL CARD */
.swap-confirmation-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.swap-confirmation-header {
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 10px;
}

.swap-confirmation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

/** CARD CONTENT*/
.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-number {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006bac;
  color: black;
  height: 100px;
  width: 120px;
  border: 2px solid black;
  border-radius: 10px;
  cursor: grab;
}

.card-number:hover {
  transform: scale(1.05);
}

.cardFixed {
  background-color: #c2ebfa;
}

.fixieren-container {
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  padding: 4px 12px;
  margin: 10px;
}

.fixieren-container:hover {
  text-decoration: underline;
}

.btn-container {
  display: flex;
  justify-content: space-evenly;
  margin: 10px;
}

.btn {
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px;
}

.btnContainerNotVisible {
  visibility: hidden;
}
</style>
