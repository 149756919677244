<template>
  <div class="merge-vis-container">
    <div id="buttons">
      <button @click="mergeSort">Merge Sort</button>
      <button @click="stop = true" style="margin-left:40px">Stop</button>
      <button @click="reset">Reset</button>
    </div>

    <div id="merge-vis"></div>

    <div>Steps: <span id="merge-counter">0</span></div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  data() {
    return {
      durationTime: 0,

      array: [],
      unsortedArray: [],
      sortedArray: [],
      count: 0,

      stop: false,
      steps: 0,

      margin: { top: 40, right: 0, bottom: 0, left: 0 },
      width: 0,
      height: 0,
      barWidth: 0,

      x: null,
      svg: null,
      rects: null,
      labels: null
    }
  },

  props: ['n' , 'stepDuration'],

  mounted() {
    this.count = Number(this.n) + 1;
    this.array = d3.shuffle(d3.range(1, this.count))
    this.durationTime = Number(this.stepDuration);
    this.unsortedArray = [...this.array];
    this.sortedArray = [];


    this.width = 700 - this.margin.left - this.margin.right;
    this.height = 300 - this.margin.top - this.margin.bottom;
    this.barWidth = this.width / this.count;

    this.x = d3.scaleLinear()
      .domain([0, this.count])
      .range([0, this.width]);

    this.svg = d3.select("#merge-vis").append("svg")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .append("g")
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    this.rects = this.svg.append("g")
      .attr("transform", "translate(" + this.barWidth + ",2)")
      .selectAll("rect")
      .data(this.unsortedArray)
      .enter().append("rect");

    this.labels = this.svg.selectAll("text")
      .data(this.unsortedArray)
      .enter().append("text");


    this.labels.attr("id", function (d) { return "merge-text" + d })
      .attr('x', (d, i) => { return this.x(i) })
      .attr('y', () => { return 0 })
      .html(function (d) { return d; });

    this.rects.attr("id", function (d) { return "merge-rect" + d })
      .attr("width", this.barWidth * .9)
      .attr("height", (d) => { return (d * this.barWidth / 3) })
      .attr('x', (d, i) => { return this.x(i) - this.barWidth })
      .attr('y', (d) => { return this.height - (d * this.barWidth / 3) })
  },


  methods: {
    async reset() {
      this.stop = true;

      await d3.timeout(() => {
        // Reset arrays
        this.unsortedArray = [...this.array];
        this.sortedArray = [];
        this.stop = false;

        d3.select("#merge-counter").html(this.steps = 0) // Reset step counter

        this.labels.attr("class", "")
          .classed("testing", false)
          .classed("sorted", false)
          .transition().duration(2000)
          .attr("x", (d, i) => { return this.x(i); })

        this.rects.attr("class", "")
          .transition().duration(2000)
          .attr("x", (d, i) => { return this.x(i - 1); })

      }, this.durationTime * 2);


    },


    mergeSort() {
      let mergeReps = (this.unsortedArray.length).toString(2).length + 1;
      let mergeArrays = [[...this.unsortedArray], []];

      for (let i = 0; i < this.unsortedArray.length; i += 2) {
        mergeArrays[1].push(mergeTwo([this.unsortedArray[i]], [this.unsortedArray[i + 1]]))
      }
      for (let n = 2; n < mergeReps; n++) {
        mergeArrays[n] = [];
        var unMerged = mergeArrays[n - 1];
        for (let i = 0; i < unMerged.length; i += 2) {
          mergeArrays[n].push(mergeTwo(unMerged[i], unMerged[i + 1] ? unMerged[i + 1] : []))
        }
      }
      for (let i = 1; i < mergeArrays.length; i++) {
        mergeArrays[i] = d3.merge(mergeArrays[i])
      }
      mergeMove(0, this);

      function mergeTwo(iArray, nArray) {
        var newArray = [];
        for (var i = 0, n = 0; i < iArray.length || n < nArray.length;) {
          if (iArray[i] < nArray[n]) {
            newArray.push(iArray[i++])
          } else if (iArray[i] > nArray[n]) {
            newArray.push(nArray[n++])
          } else if (!(iArray[i])) {
            newArray.push(nArray[n++])
          } else if (!(nArray[n])) {
            newArray.push(iArray[i++])
          }
        }
        return newArray;
      }

      function mergeMove(j, that) {
        let oldArray = mergeArrays[j];
        let newArray = [...mergeArrays[j + 1]];
        let sortedArray = [];

        moveStep(0, that);

        function moveStep(n, that) {
          if (that.stop) return that.stop = false;
          d3.selectAll("rect").attr("class", "")

          d3.select("#merge-counter").html(++that.steps);
          d3.select("#merge-rect" + newArray[n]).attr("class", "testing")

          sortedArray.push(newArray[n])
          oldArray.shift()

          that.rects
            .classed("sorted", function (d) {
              return !mergeArrays[j + 2] && sortedArray.indexOf(d) == d - 1;
            })
            .transition().duration(that.durationTime)
            .attr("x", function (d) {
              var xVal = sortedArray.indexOf(d) > -1 ? sortedArray.indexOf(d) : oldArray.indexOf(d) + sortedArray.length;
              return that.x(xVal - 1);
            })

          that.labels
            .classed("sorted", function (d) {
              return !mergeArrays[j + 2] && sortedArray.indexOf(d) == d - 1;
            })
            .transition().duration(that.durationTime)
            .attr("x", function (d) {
              var xVal = sortedArray.indexOf(d) > -1 ? sortedArray.indexOf(d) : oldArray.indexOf(d) + sortedArray.length;
              return that.x(xVal);
            })

          d3.timeout(function () {
            if (oldArray.length > 0) {
              moveStep(++n, that)
            } else if (mergeArrays[j + 2]) {
              mergeMove(++j, that)
            } else {
              that.rects.classed("testing", false)
            }
          }, that.durationTime);
        }
      }

    },

    // Slides the text and rect of d to the position of index i 
    slide(d, i) {
      d3.select("#merge-text" + d)
        .transition().duration(this.durationTime)
        .attr("x", () => { return this.x(i); })

      d3.select("#merge-rect" + d)
        .transition().duration(this.durationTime)
        .attr("x", () => { return this.x(i - 1); })
    }


  }

}
</script>


<style scoped>

#buttons > button {
  background-color: darkgreen;
  border-radius: 5px;
  color: white;
  margin-right: 10px;
}

.merge-vis-container {
  margin: 20px;
  margin-left: 20px;
}

#merge-vis {
  margin: 10px 0;
}

#merge-vis :deep(text) {
  fill: black;
}

#merge-vis :deep(rect) {
  fill: darkgreen;
}

#merge-vis :deep(.sorted) {
  fill: #999;
}

#merge-vis :deep(.min) {
  fill: red;
}

#merge-vis :deep(.testing) {
  fill: orange;
}
</style>