<template>
  <div class="container" style="padding-bottom: 100px">
    <AssignmentbarSorting1
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    ></AssignmentbarSorting1>
    <div class="row">
      <div class="animate glow tutorial-intro-text-container">
        <h2>Aber nun zur Aufgabe:</h2>
        <p>
          <strong
            >Um die Aufgabe erfolgreich bearbeiten zu können, gilt es einige
            Regeln zu beachten. Mit diesen möchten wir Sie in diesem Schritt
            bekannt machen.</strong
          >
        </p>
        <p>
          Vor Ihnen befindet sich eine Reihe von Karten, wobei nur deren (leere)
          Rückseiten zu sehen sind. Die Vorderseiten sind mit einer Zahl
          versehen, die sich in der Reihe in dieser Aufgabe nicht wiederholt.
          Die Karten sind zufällig angeordnet und jede Karte nimmt genau einen
          Platz ein. Ihre Aufgabe besteht nun darin, alle Karten nach der Größe
          ihres Zahlenwertes zu sortieren, sodass jene mit dem geringsten Wert
          ganz links und jene mit dem größten ganz rechts zu liegen kommt.
        </p>
        <p>
          <em
            >Für die Bearbeitung dieser Aufgabe sollten Sie ca. 15 Minuten und
            evtl. einen Zeitpuffer vorsehen. Die Bearbeitung darf nicht
            unterbrochen werden, wenn Sie eine Rückmeldung zur Lösung erhalten
            wollen. Bitte nehmen Sie diese Aufgabe nur in Angriff, wenn Sie über
            ausreichend Zeit verfügen und die Aufgabe in Ruhe bis zum Ende
            bearbeiten können.
          </em>
        </p>
        <!--<div
          class="custom-btn"
          :class="{ 'custom-btn-disabled': showTutorialContainer }"
          @click="showTutorial()"
        >
          Weiter
        </div>-->
      </div>
    </div>
    <div class="tutorial-container" v-if="true">
      <div class="tutorial-slides-container">
        <SlidesTutorial
          :startAssignmentClicked="startAssignmentClicked"
          @next-page-clicked="startAssignmentClicked = false"
          @tutorial-finished="tutorialFinished()"
          @start-assignment="startAssignment()"
        ></SlidesTutorial>
      </div>
      <!--    <div @click="this.$emit('assignment1-tutorial-finished')" class="skip-tutorial" v-if="!showStartAssignmentBtn">
        Anleitung überspringen
      </div>

     <div class="custom-btn-secondary" @click="backBtnClicked">Zurück</div>-->
      <div class="tutorial-btn-container">
        <div
          id="start-assignment-btn"
          class="custom-btn" 
          :class="!showStartAssignmentBtn ? 'custom-btn-disabled' : ''"
          @click="startAssignment"
        >
          Aufgabe starten
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SlidesTutorial from "@/components/inf/sorting/Assignment1/Tutorial/SlidesTutorial.vue";
import AssignmentbarSorting1 from "@/components/inf/sorting/Assignment1/AssignmentbarSorting1.vue";
import * as scrolling from "@/helpers/scroll-helper.js";

export default {
  components: {
    SlidesTutorial,
    AssignmentbarSorting1,
  },
  emits: ["assignment1-tutorial-back", "assignment1-tutorial-finished"],
  data() {
    return {
      showStartAssignmentBtn: false,
      startAssignmentClicked: false,
      showTutorialContainer: false,
    };
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  methods: {
    async showTutorial() {
      this.showTutorialContainer = true;
      await this.$nextTick();
      setTimeout(function () {
        scrolling.scrollToElementByClassName("tutorial-slides-container", {
          behavior: "smooth",
        });
      }, 80);
    },
    backBtnClicked() {
      this.$emit("assignment1-tutorial-back");
    },
    async tutorialFinished() {
      this.showStartAssignmentBtn = true;
     /* await this.$nextTick();
      scrolling.scrollToElementByClassName("tutorial-btn-container", {
        behavior: "smooth",
      });*/
    },
    startAssignment() {
      this.startAssignmentClicked = true;
      if (this.showStartAssignmentBtn) {
        this.$emit("assignment1-tutorial-finished");
      }
    },
  },
};
</script>

<style scoped>
p{
  line-height: 1.3;
}
.tutorial-container {
  display: flex;
  flex-flow: column wrap;
}

.custom-btn,
.custom-btn-secondary {
  float: right;
}

#tutorial-slides-container {
  margin-top: 30px;
}

.skip-tutorial {
  cursor: pointer;
  float: left;
}

.skip-tutorial:hover {
  text-decoration: underline;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
</style>