<template>
  <div class="container">
    <AssignmentbarSorting1
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    ></AssignmentbarSorting1>
    <div>
      <div class="row animate glow delay-1">
      <h2>Sortieren mit System!</h2>
      <div class=" part1" v-if="!showPart2">
        <p>
          Wir sortieren jeden Tag: den Einkauf in den Kühlschrank, die
          eingegangene Post zu den Adressaten, die Spielkarten nach Farbe und
          Wert - und manchmal sogar den Schreibtisch, wenn wir den Eindruck
          haben, gar nichts mehr zu finden oder weil wir ratlos sind und bei der
          Lösung schwieriger Probleme nicht vorankommen!
        </p>
        <p>
          <strong
            >In der Informatik sprechen wir von Sortieren, wenn wir Elemente
            nach einem bestimmten Kriterium ordnen möchten.</strong
          >
          So werden Dateien im Dateiexplorer oft nach Namen oder nach letztem
          Änderungsdatum, E-Mails nach dem Versendezeitpunkt und Posts in
          sozialen Medien nach ganz komplizierten Verfahren sortiert. Bei den
          folgenden Aufgaben wollen wir es jedoch einfach halten und Karten mit
          aufgedruckter Zahl sortieren.
        </p>
        <div class="text-highlight-area">
          <p>
            Die Herangehensweise von Menschen folgt beim Sortieren keinen bestimmten
            Regeln und wir wissen intuitiv, wie wir richtig vorgehen. Vor Ihnen
            befinden sich nun fünf Karten mit einstelligen Zahlenwerten. Bevor
            Sie die Liste der Karten sortieren, versuchen Sie sich kurz zu
            überlegen, welche konkreten Schritte Sie bei der Bearbeitung
            durchführen werden. Versuchen Sie danach die Karten mittels
            Drag & Drop (Ziehen und Fallenlassen) zu sortieren.
          </p>
          <!-- VUE DRAG AND DROP LIBRARY https://github.com/amendx/vue-dndrop
      ----------- BUG:: LIBRARY NOT WORKING IN PRODUCTION BUILD VERSION -----------------
      <div style="overflow-x: auto">
        <Container @drop="onDrop" orientation="horizontal" behaviour="contain">
          <Draggable v-for="item in items" :key="item.id">
            <div class="draggable-item-horizontal">
              {{ item.data }}
            </div>
          </Draggable>
        </Container>
      </div>-->

          <div>
            <draggable
              :list="sortableList"
              :disabled="false"
              class="draggable-item-horizontal"
              ghost-class="ghost"
              @start="dragging = true"
              @end="dragging = false"
              item-key="number"
            >
              <template #item="{ element }">
                <div class="draggable-item">
                  {{ element.number }}
                </div>
              </template>
            </draggable>
          </div>
        </div>

        <div class="btn-container-right">
          <div
            class="custom-btn"
            :class="{ 'custom-btn-disabled': showPart2 }"
            @click="showPart2Clicked()"
          >
            Weiter
          </div>
        </div>
      </div>
    </div>
    </div>
    
    
    <div
      class="animate glow part2-container row"
      :class="showPart2 ? 'fade-in-text' : ''"
      v-if="showPart2"
    >
      <p>
        Wie Sie bemerkt haben, sind für uns Menschen solche Aufgaben, wie das
        aufsteigende Sortieren von fünf Karten mit einstelligen natürlichen
        Zahlen, keine große Sache. Wir übersehen die 5 Karten mit einem Blick,
        finden jene mit der kleinsten Zahl sofort heraus, dann die nächste, usw.
        - fertig! Wir brauchen keinen Plan, um ein Problem dieser Größenordnung
        zu lösen. Weil wir uns vieles „im Vorübergehen“ merken und leicht unser
        Vorgehen anpassen können, sind wir sehr flexibel und schnell - aber nur
        bei der Lösung kleiner Probleme.
      </p>
      <!--         <div class="btn-container">
          <div
            class="custom-btn"
            :class="{ 'custom-btn-disabled': showPart3 }"
            @click="showPart3Clicked()"
          >
            Aber wie sortieren Computer?
          </div>
        </div>
      </div>
      <div
        class="part3-container row"
        :class="showPart3 ? 'fade-in-text' : ''"
        v-if="showPart3"> -->
      <h3>Wie sortieren Computer?</h3>
      <p>
        Ein Computerprogramm benötigt schon vor Beginn der Bearbeitung einer
        solchen Aufgabe einen Plan, wie das Problem gelöst werden soll. Das mag
        bei einer geringen Zahl von Sortierobjekten umständlich und unökonomisch
        sein. Ist dieser Plan aber einmal festgelegt, braucht ein Computer im
        Gegensatz zum Menschen für 1 Million Karten nicht merkbar länger als für
        fünf.
      </p>
      <p>
        Wie sieht nun so ein Plan aus? Alle Dinge, die ein Computer tun soll,
        müssen ihm vorgegeben werden. Diese Vorgaben werden
        <strong>Handlungsvorschriften oder Algorithmen</strong> genannt und
        bestehen aus
        <strong
          >endlich vielen Schritten von eindeutig ausführbaren
          Anweisungen.</strong
        >
      </p>
      <p>
        Computer werden meist dazu eingesetzt, um vordefinierte Probleme zu
        lösen. Das können komplizierte Berechnungen bei Weltraummissionen sein
        oder einfache Probleme, wie das Sortieren von Zahlen. Beide Probleme
        haben gemein, dass es einen definierten Endzustand gibt und dass sich
        das Problem durch einen Algorithmus lösen lässt. Dabei soll bei gleichen
        Voraussetzungen stets das gleiche Ergebnis erzielt werden.
      </p>
      <!--         <div class="btn-container">
          <div
            class="custom-btn"
            :class="{ 'custom-btn-disabled': showPart4 }"
            @click="showPart4Clicked()"
          >
            Weiter
          </div>
        </div>
      </div>
      <div
        class="part4-container row"
        :class="showPart4 ? 'fade-in-text' : ''"
        v-if="showPart4"
      > -->

     <!--<h3>Ihre Aufgabe in diesem Modul</h3>
      <p>
        Bei der Lösung der folgenden Kartensortieraufgabe sollen Sie nach Regeln
        vorgehen, die jenen ähneln, nach denen auch Computerprogramme arbeiten.
        Die damit verbundene Denkweise scheint uns Menschen bisweilen fremd,
        aber sie entspricht den besonderen Voraussetzungen der „Denkmaschine“
        Computer. Hat man diese einmal verstanden, lassen sich damit viele
        Probleme in einer überlegenen Weise lösen, zu der wir als Menschen ohne
        Computer nicht in der Lage wären.
      </p>
      <p>
        Bei der Bearbeitung des ersten Teils der Aufgabe werden Sie vielleicht
        ein Muster erkennen, wie Sie vorgegangen sind, um alle Karten zu
        sortieren. Hier schließt der zweite Teil der Aufgabe an, bei dem es
        darum gehen wird, dieses Muster in einer eindeutigen Handlungsvorschrift
        (einem Algorithmus) zu beschreiben. Ein Sortieralgorithmus soll hier
        gewährleisten, dass nicht mehr Sie selbst die Karten lesen und tauschen
        müssen, sondern ein Programm diese Aufgabe für Sie übernimmt.
      </p>--> 
      
      <div class="btn-container-right">
        <div class="custom-btn" @click="endIntroduction()">Weiter</div>
      </div>
      
    </div>
  </div>

</template>

<script>
/** vue-dndrop library https://github.com/amendx/vue-dndrop
import { Container, Draggable } from "vue-dndrop";
import { applyDrag } from "@/helpers/drag-and-drop_helper.js";*/
import AssignmentbarSorting1 from "@/components/inf/sorting/Assignment1/AssignmentbarSorting1.vue";
import draggable from "vuedraggable";
import * as scrolling from "@/helpers/scroll-helper.js";

export default {
  name: "SortingIntroduction",
  components: {
    draggable,
    AssignmentbarSorting1,
  },
  emits: ["sorting-intro-finished", "start-sorting-intro2-shortcut"],
  mounted() {
    scrolling.scrollToElementByClassName("container", { behavior: "smooth" });
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      playingCards: require("@/assets/inf/sorting/sorting_playing_cards.png"),
      strawsUnsorted: require("@/assets/inf/sorting/straws_unsorted.jpg"),
      strawsSorted: require("@/assets/inf/sorting/straws_sorted.jpg"),
      sortableList: [
        {
          id: 1,
          number: 6,
        },
        {
          id: 2,
          number: 5,
        },
        {
          id: 3,
          number: 8,
        },
        {
          id: 4,
          number: 3,
        },
        {
          id: 5,
          number: 4,
        },
      ],
      dragging: false,
      showPart2: false,
      showPart3: false,
      showPart4: false,
    };
  },
  methods: {
    endIntroduction() {
      this.$emit("sorting-intro-finished");
    },
    /** vue-dndrop library methods 
    onDrop(dropResult) {
      console.log(dropResult);
      this.items = applyDrag(this.items, dropResult);
    },*/
    async showPart2Clicked() {
      this.showPart2 = true;
      await this.$nextTick();
      setTimeout(function () {
        scrolling.scrollToElementByClassName("part2-container", {
          behavior: "smooth",
        });
        
      }, 80);
    },
    async showPart3Clicked() {
      this.showPart3 = true;
      await this.$nextTick();
      setTimeout(function () {
        scrolling.scrollToElementByClassName("part3-container", {
          behavior: "smooth",
        });
      }, 80);
    },
    async showPart4Clicked() {
      this.showPart4 = true;
      await this.$nextTick();
      setTimeout(function () {
        scrolling.scrollToElementByClassName("part4-container", {
          behavior: "smooth",
        });
      }, 80);
    },
  },
};
</script>

<style scoped lang="scss">
p{
  line-height: 1.3;
}
.btn-container-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 20px;
}
.draggable-item-horizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}
.draggable-item {
  border: 2px solid black;
  border-radius: 20px;
  background: white;
  width: 40px;
  height: 40px;
  margin: 20px 0;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
}
@media only screen and (max-width: 767px) {
  .draggable-item {
    padding: 30px;
  }


  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-moz-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-o-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-ms-keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.delay-1 {
  animation-delay: 0.2s;
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
</style>
