<template>
  <div>
    <div class="assignmentbar" style="sticky">
      <AssignmentbarSorting2 :showHelpBtn="true" :showDismissBtn="true" :showFinishBtn="false"
        :sortingFinished="isAssignmentFinished" @finish-continue-clicked="$emit('assignment2-finished')">
      </AssignmentbarSorting2>
    </div>

    <div id="assignment-container">
      <div id="card-container" @drop="dropCardContainerHandler($event)" @dragover.prevent @dragenter.prevent>
        <div class="sticky-top" style="
            background-color: white;
            font-size: 1.1em;
            padding-left: 1em;
            border-bottom: 2px solid black;
          ">
          Anweisungen
        </div>
        <div class="card" id="card6" draggable="true" @dragstart="dragstartHandler($event, 'card6')">
          Vertausche die Karte unter dem
          <span class="merkzeiger-word">MERKZEIGER</span> mit der ganz
          <select id="swap-merkzeiger-select" class="custom-select" v-model="swapSaveSelect"
            @change="selectChangeAction()">
            <option selected>links</option>
            <option>rechts</option>
          </select>
          befindlichen nichtfixierten Karte.
        </div>
        <div class="card" id="card3" draggable="true" @dragstart="dragstartHandler($event, 'card3')">
          Setze <span class="lesezeiger-word">LESEZEIGER</span> eine Position
          nach
          <select id="move-lesezeiger-select" class="custom-select" v-model="moveReadSelect"
            @change="selectChangeAction()">
            <option selected>links</option>
            <option>rechts</option>
          </select>.
        </div>
        <div class="card" :class="grabbedCard === 'card9' ? 'card-grabbed' : ''" id="card9" draggable="true"
          @dragstart="dragstartHandler($event, 'card9')">
          Fixiere die letzte Karte und beende den Algorithmus.
        </div>
        <div class="card" id="card2" draggable="true" @dragstart="dragstartHandler($event, 'card2')">
          Setze <span class="merkzeiger-word">MERKZEIGER</span> auf die ganz
          <select id="set-merkzeiger-select" class="custom-select" v-model="initSaveSelect"
            @change="selectChangeAction()">
            <option selected>links</option>
            <option>rechts</option>
          </select>
          befindliche nichtfixierte Karte.
        </div>

        <div class="card" id="card5" draggable="true" @dragstart="dragstartHandler($event, 'card5')">
          Wenn der <span class="lesezeiger-word">LESEZEIGER</span> auf der ganz
          <select id="position-lesezeiger-select" class="custom-select" v-model="jumpReadSelect"
            @change="selectChangeAction()">
            <option selected>links</option>
            <option>rechts</option>
          </select>
          befindlichen, nicht fixierten Karte liegt, gehe weiter zum nächsten
          Schritt. Sonst springe zu Schritt 3.
        </div>

        <div class="card" id="card1" draggable="true" @dragstart="dragstartHandler($event, 'card1')">
          Setze <span class="lesezeiger-word">LESEZEIGER</span> auf die ganz
          <select class="custom-select" aria-label="Initialisierung Lesezeiger Richtung" v-model="initReadSelect"
            @change="selectChangeAction()">
            <option selected>links</option>
            <option>rechts</option>
          </select>
          befindliche nichtfixierte Karte.
        </div>

        <div class="card" id="card7" draggable="true" @dragstart="dragstartHandler($event, 'card7')">
          Fixiere die ganz
          <select id="fix-select" class="custom-select" v-model="fixCardSelect" @change="selectChangeAction()">
            <option selected>links</option>
            <option>rechts</option>
          </select>
          befindliche nichtfixierte Karte.
        </div>
        <div class="card" id="card4" draggable="true" @dragstart="dragstartHandler($event, 'card4')">
          Wenn die Zahl beim
          <span class="lesezeiger-word">LESEZEIGER</span>
          <select id="compare-cards-select" class="custom-select" v-model="compareCardsSelect"
            @change="selectChangeAction()">
            <option selected>kleiner</option>
            <option>größer</option>
          </select>
          ist als die Zahl beim <span class="merkzeiger-word">MERKZEIGER</span>,
          dann setze den <span class="merkzeiger-word">MERKZEIGER</span> auf die
          Position des <span class="lesezeiger-word">LESEZEIGER</span>.
        </div>
        <div class="card" :class="grabbedCard === 'card8' ? 'card-grabbed' : ''" id="card8" draggable="true"
          @dragstart="dragstartHandler($event, 'card8')">
          So lange es mindestens zwei nichtfixierte Karten gibt, springe zu
          Schritt 1.
        </div>
      </div>
      <div id="algorithm-container">
        <div class="drop-slot-container">
          1
          <div class="drop-slot" :class="tickPosition == 1 ? 'drop-slot-active' : ''" id="drop-slot1"
            @drop="dropSlotHandler($event, 'drop-slot1')" @dragover.prevent @dragenter.prevent></div>
        </div>
        <div class="drop-slot-container">
          2
          <div class="drop-slot" :class="tickPosition == 2 ? 'drop-slot-active' : ''" id="drop-slot2"
            @drop="dropSlotHandler($event, 'drop-slot2')" @dragover.prevent @dragenter.prevent></div>
        </div>
        <div class="drop-slot-container">
          3
          <div class="drop-slot" :class="tickPosition == 3 ? 'drop-slot-active' : ''" id="drop-slot3"
            @drop="dropSlotHandler($event, 'drop-slot3')" @dragover.prevent @dragenter.prevent></div>
        </div>
        <div class="drop-slot-container">
          4
          <div class="drop-slot" :class="tickPosition == 4 ? 'drop-slot-active' : ''" id="drop-slot4"
            @drop="dropSlotHandler($event, 'drop-slot4')" @dragover.prevent @dragenter.prevent></div>
        </div>
        <div class="drop-slot-container">
          5
          <div class="drop-slot" :class="tickPosition == 5 ? 'drop-slot-active' : ''" id="drop-slot5"
            @drop="dropSlotHandler($event, 'drop-slot5')" @dragover.prevent @dragenter.prevent></div>
        </div>
        <div class="drop-slot-container">
          6
          <div class="drop-slot" :class="tickPosition == 6 ? 'drop-slot-active' : ''" id="drop-slot6"
            @drop="dropSlotHandler($event, 'drop-slot6')" @dragover.prevent @dragenter.prevent></div>
        </div>
        <div class="drop-slot-container">
          7
          <div class="drop-slot" :class="tickPosition == 7 ? 'drop-slot-active' : ''" id="drop-slot7"
            @drop="dropSlotHandler($event, 'drop-slot7')" @dragover.prevent @dragenter.prevent></div>
        </div>
        <div class="drop-slot-container">
          8
          <div class="drop-slot" :class="tickPosition == 8 ? 'drop-slot-active' : ''" id="drop-slot8"
            @drop="dropSlotHandler($event, 'drop-slot8')" @dragover.prevent @dragenter.prevent></div>
        </div>
        <div class="drop-slot-container">
          9
          <div class="drop-slot" :class="tickPosition == 9 ? 'drop-slot-active' : ''" id="drop-slot9"
            @drop="dropSlotHandler($event, 'drop-slot9')" @dragover.prevent @dragenter.prevent></div>
        </div>
      </div>
      <div id="algorithm-button-container">
        <div :class="{ disabled: workspaceLocked || isAssignmentFinished }">
        <!-- Geschwindigkeit:
          <select
            id="algorithm-speed-select"
            class="custom-select"
            v-model="algoSpeedSelect"
          >
            <option>sehr langsam</option>
            <option>langsam</option>
            <option selected>mittel</option>
            <option>schnell</option>
            <option>sehr schnell</option>
          </select> -->
        <button class="button" @click="playPauseBtnClicked()" title="Algorithmus starten/pausieren">
          <i class="bi bi-play-circle" v-if="!this.tick"></i>
          <i class="bi bi-pause-circle" v-else></i>
        </button>
<!--         <button class="button" @click="pauseAlgorithm()" title="Algorithmus pausieren">
        </button> -->
        <button class="button" @click="stopBtnClicked()" title="Algorithmus stoppen">
          <i class="bi bi-stop-circle"> </i>
        </button>
        <button class="button" @click="stepForwardBtnClicked()" title="Schritt vorwärts">
          <i class="bi bi-arrow-down-circle"> </i>
        </button>
        <!--<button
          class="button"
          @click="resetAlgorithmBtnClicked()"
          title="Algorithmus zurücksetzen"
        >
          <i class="bi bi-arrow-counterclockwise"> </i>
        </button>-->

      </div>
      
      <div class="feedback-hint" @click="scrollToFeedback()">Zum Feedback</div>
    </div>
    </div>
    <div class="number-list-container">
      <ul>
        <li v-for="card in cardList" :key="card.id">
          <transition name="flip">
            <div class="number-list-card" :class="{
              cardFlipped: card.cardFlipped,
              cardFixed: card.fixActive,
            }" :key="card.flipped">
              {{ card.cardFlipped || card.fixActive ? card.number : "" }}
            </div>
          </transition>
          <div class="arrow-container">
            <ArrowIcon class="read-arrow" :color="card.lesenActive ? 'orange' : 'invisible'" />
            <ArrowIcon class="save-arrow" :color="card.merkenActive ? 'green' : 'invisible'" />
          </div>
        </li>
      </ul>
    </div>
    <div class="feedback-container">
      <Assignment2FeedbackField :feedbackMessage="feedbackMessage" :lastAction="lastAction"
        :showLastAction="feedbackMessage == '' ? true : false" :isAssignmentFinished="isAssignmentFinished"
        @stop-algorithm="stopBtnClicked()" @finish-assignment="$emit('assignment2-finished')">
      </Assignment2FeedbackField>
    </div>
  </div>
</template>

<script>
import AssignmentbarSorting2 from "@/components/inf/sorting/Assignment2/AssignmentbarSorting2.vue";
import Assignment2FeedbackField from "@/components/inf/sorting/Assignment2/Assignment2FeedbackField.vue";
import ArrowIcon from "@/components/icons/ArrowIcon.vue";
import * as scrolling from "@/helpers/scroll-helper.js";
import store from "@/store.js";

export default {
  components: {
    AssignmentbarSorting2,
    Assignment2FeedbackField,
    ArrowIcon,
  },
  emits: ["assignment2-finished"],
  mounted() {
    scrolling.scrollToElementById("assignment-container", {
      behavior: "smooth",
    });
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
    window.setInterval(() => {
      this.autoRunAlgorithm();
    }, 1500);
    this.assignment2Results.startTime = new Date();
  },
  unmounted() {
    if (this.assignment2Results.endTime == null) {
      //assignment2 is not finished --> no endtime is set yet
      this.assignment2Results.endTime = new Date();
    }

    //set needed time in seconds
    this.assignment2Results.neededTimeSeconds =
      this.assignment2Results.endTime.getTime() / 1000 -
      this.assignment2Results.startTime.getTime() / 1000;

    //set average time: needed time / performed actions
    this.assignment2Results.averageInterActionTimeSeconds =
      this.assignment2Results.neededTimeSeconds /
      (this.assignment2Results.dropActionsCardContainer +
        this.assignment2Results.dropActionsSlot +
        this.assignment2Results.changedDropdowns);

    store.commit('sortingA2Update', this.assignment2Results);
    store.dispatch('sortingTotalResultsPost');
  },
  data() {
    return {
      autorun: {},
      isAssignmentFinished: false,
      grabbedCard: "",
      tick: false,
      tickPosition: 1,
      cardsFixed: 0,
      initReadSelect: "",
      initSaveSelect: "",
      moveReadSelect: "",
      jumpReadSelect: "",
      compareCardsSelect: "",
      swapSaveSelect: "",
      fixCardSelect: "",
      feedbackMessage: "",
      lastAction: "",
      workspaceLocked: false,
      algoSpeedSelect: "",
      timestampLastAction: null,
      cardList: [
        {
          id: 1,
          number: 5,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
        },
        {
          id: 2,
          number: 4,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
        },
        {
          id: 1,
          number: 1,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
        },
        {
          id: 4,
          number: 3,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
        },
        {
          id: 5,
          number: 2,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
        },
      ],
      resetCardList: [
        {
          id: 1,
          number: 5,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
        },
        {
          id: 2,
          number: 4,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
        },
        {
          id: 1,
          number: 1,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
        },
        {
          id: 4,
          number: 3,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
        },
        {
          id: 5,
          number: 2,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
        },
      ],
      assignment2Results: {
        assignment2Finished: false,
        startTime: null,
        endTime: null,
        neededTimeSeconds: 0,
        secondsToFirstAction: 0,
        averageInterActionTimeSeconds: 0,
        shortestInterActionTimeSeconds: 0,
        longestInterActionTimeSeconds: 0,
        dropActionsCardContainer: 0,
        dropActionsSlot: 0,
        changedDropdowns: 0,
        emptySlotErrors: 0,
        usedSaveWithoutSettingSaveErrors: 0,
        usedReadWithoutSettingReadErrors: 0,
        readOutOfBoundsErrors: 0,
        noCardSavedBeforeSwapErrors: 0,
        fixedAllCardsErrors: 0,
        finishedWithWrongOrderErrors: 0,
      },
    };
  },
  methods: {
    // ---------- DRAG AND DROP FUNCTIONALITY ---------------- //
    dragstartHandler(e, card) {
      this.grabbedCard = card;
    },
    dropCardContainerHandler(e) {
      e.preventDefault();
      document
        .getElementById("card-container")
        .appendChild(document.getElementById(this.grabbedCard));
      this.grabbedCard = "";
      this.assignment2Results.dropActionsCardContainer++;
      this.setTimestampsAction();
    },
    dropSlotHandler(e, cardSlot) {
      e.preventDefault();
      if (
        cardSlot.includes("drop-slot") &&
        !this.checkIfDropSlotContainsCard(cardSlot)
      ) {
        e.target.appendChild(document.getElementById(this.grabbedCard));
      }
      this.grabbedCard = "";
      this.assignment2Results.dropActionsSlot++;
      this.setTimestampsAction();
    },
    selectChangeAction() {
      this.assignment2Results.changedDropdowns++;
      this.setTimestampsAction();
    },
    setTimestampsAction() {
      if (!this.timestampLastAction) {
        //no action performed yet --> set timestamp of last action 
        this.timestampLastAction = new Date();
        //set seconds to first action in results
        this.assignment2Results.secondsToFirstAction =
          this.timestampLastAction.getTime() / 1000 -
          this.assignment2Results.startTime.getTime() / 1000;
      } else {
        let timestampCurrentAction = new Date();
        //time since last action
        let deltaTime =
          timestampCurrentAction.getTime() / 1000 -
          this.timestampLastAction.getTime() / 1000;
        //if shortest time between actions is not set or delta time is smaller than current shortest time
        if (
          this.assignment2Results.shortestInterActionTimeSeconds == 0 ||
          deltaTime < this.assignment2Results.shortestInterActionTimeSeconds
        ) {
          this.assignment2Results.shortestInterActionTimeSeconds = deltaTime;
        }
        //if longest time between actions is not set or delta time is bigger than current longest time
        if (
          this.assignment2Results.longestInterActionTimeSeconds == 0 ||
          deltaTime > this.assignment2Results.longestInterActionTimeSeconds
        ) {
          this.assignment2Results.longestInterActionTimeSeconds = deltaTime;
        }

        this.timestampLastAction = timestampCurrentAction;
      }
    },
    checkIfDropSlotContainsCard(dropSlot) {
      let parent = document.getElementById(dropSlot);
      if (parent.childNodes.length >= 1) {
        return true;
      }
      return false;
    },
    // -------------- BUTTON CLICK METHODS ------------------
    scrollToFeedback(){
      scrolling.scrollToElementByClassName('feedback-container', {
      behavior: 'smooth',
    });
    },
    getAlgoSpeed() {
      switch (this.algoSpeedSelect) {
        case "sehr langsam":
          return 3000;
        case "langsam":
          return 2000;
        case "mittel":
          return 1000;
        case "schnell":
          return 500;
        case "sehr schnell":
          return 250;
        default:
          return 1000;
      }
    },
    playPauseBtnClicked(){
      if(this.tick){
        this.pauseAlgorithm();
      }else{
        this.playBtnClicked();
      }
    },
    playBtnClicked() {
      this.tick = true; //TICKER FOR ALGORITHM --> AUTORUNALGORITHM GETS CALLED EVERY 1 SECOND
      //console.log(this.getAlgoSpeed());
      //this.autoRun = setInterval(this.stepForward(), this.getAlgoSpeed());
    },
    stopBtnClicked() {
      clearInterval(this.autoRun);
      this.removeErrorCards();
      this.stopAlgorithm();
    },
    stepForwardBtnClicked() {
      this.stepForward();
    },
    resetAlgorithmBtnClicked() {
      this.feedbackMessage = "RESET ALGORITHM TO IMPLEMENT";
    },
    // ---------- BASIC ALGORITHM METHODS ---------------- //
    createCardError(cardId, errorMessage) {
      this.workspaceLocked = true;
      this.feedbackMessage = errorMessage;
      document.getElementById(cardId).classList.add("card-error");
    },
    createSlotError(slotId, errorMessage) {
      this.workspaceLocked = true;
      this.feedbackMessage = errorMessage;
      document.getElementById(slotId).classList.add("drop-slot-error");
    },
    removeErrorCards() {
      this.feedbackMessage = "";
      this.lastAction = "";
      var cards = document.getElementsByClassName("card");
      for (var card of cards) {
        card.classList.remove("card-error");
      }

      var dropSlots = document.getElementsByClassName("card");
      for (var dropSlot of dropSlots) {
        dropSlot.classList.remove("drop-slot-error");
      }
    },
    autoRunAlgorithm() {
      if (this.tick) {
        this.stepForward();
      }
    },
    stopAlgorithm() {
      this.tick = false;
      this.tickPosition = 1;
      this.cardsFixed = 0;
      this.cardList = JSON.parse(JSON.stringify(this.resetCardList));
      this.lastAction = "";
      this.workspaceLocked = false;
    },
    pauseAlgorithm() {
      this.tick = false;
      clearInterval(this.autorun);
    },
    stepForward() {
      if (!this.workspaceLocked) {
        let parent = document.getElementById("drop-slot" + this.tickPosition);
        if (parent.childNodes.length <= 0) {
          //parent(=slot) does not have a child(=contains a card)
          this.createSlotError(
            "drop-slot" + this.tickPosition,
            "Sie haben die Schritte des Algorithmus noch nicht fertig befüllt. Verschieben Sie Skripte mittels Drag-And-Drop auf die fehlenden Teile des Algorithmus."
          );
          this.assignment2Results.emptySlotErrors++;
          return;
        }
        let child = parent.childNodes[0];
        this.doStep(child.id);
      }
    },
    doStep(cardId) {
      switch (cardId) {
        case "card1":
          this.moveReadToFirst();
          this.increaseTickPosition();
          break;
        case "card2":
          this.moveSaveToFirst();
          this.increaseTickPosition();
          break;
        case "card3":
          this.moveReadToNext();
          this.increaseTickPosition();
          break;
        case "card4":
          this.moveSaveToRead();
          this.increaseTickPosition();
          break;
        case "card5":
          this.jumpToStep3();
          break;
        case "card6":
          this.swapSaveWithFirst();
          this.increaseTickPosition();
          break;
        case "card7":
          this.fixSortedCard();
          this.increaseTickPosition();
          break;
        case "card8":
          this.jumpToStep1();
          break;
        case "card9":
          this.fixLastCard();
          break;
        default:
          this.feedbackMessage = "Something went wrong";
          console.error(
            "CURRENT CARD NOT RECOGNIZED \n ID of next card is " +
            cardId +
            ". Card IDs must be between 0 and 9"
          );
      }
    },
    //------------------- SORTING LIST HELPER METHODS -------------- //
    getCard(cardId) {
      return this.cardList.find((card) => card.id === cardId);
    },
    increaseTickPosition() {
      this.tickPosition = (this.tickPosition % 9) + 1;
    },
    flipCard(card) {
      if (card.lesenActive || card.merkenActive) {
        card.cardFlipped = true;
      } else if (!card.lesenActive && !card.merkenActive) {
        card.cardFlipped = false;
      }
    },
    unreadAllCards() {
      this.cardList.forEach((card) => {
        if (card.lesenActive) {
          card.lesenActive = false;
          this.flipCard(card);
        }
      });
    },
    unsaveAllCards() {
      this.cardList.forEach((card) => {
        if (card.merkenActive) {
          card.merkenActive = false;
          this.flipCard(card);
        }
      });
    },

    // CARD 1
    moveReadToFirst() {
      //console.log("moveReadToFirst");
      if (!this.initReadSelect) {
        this.createCardError(
          "card1",
          "Bitte setzen sie eine zu lesende Karte für das markierte Skript."
        );
        return;
      }
      let firstUnfixedCard = this.getFirstUnfixedCard(this.initReadSelect);
      this.unreadAllCards();
      this.cardList[firstUnfixedCard].lesenActive = true;
      this.cardList[firstUnfixedCard].cardFlipped = true;
      this.lastAction = "Lesezeiger auf erste Karte " + this.initReadSelect;
    },
    getFirstUnfixedCard(direction) {
      //console.log(direction);
      if (direction === "links") {
        if (!this.cardList[0].fixActive) return 0;
        else if (!this.cardList[1].fixActive) return 1;
        else if (!this.cardList[2].fixActive) return 2;
        else if (!this.cardList[3].fixActive) return 3;
        else if (!this.cardList[4].fixActive) return 4;
      } else if (direction === "rechts") {
        if (!this.cardList[4].fixActive) return 4;
        else if (!this.cardList[3].fixActive) return 3;
        else if (!this.cardList[2].fixActive) return 2;
        else if (!this.cardList[1].fixActive) return 1;
        else if (!this.cardList[0].fixActive) return 0;
      }
    },
    //CARD 2
    moveSaveToFirst() {
      //console.log("moveSaveToFirst");
      if (!this.initSaveSelect) {
        this.createCardError(
          "card2",
          "Bitte setzen sie eine zu merkende Karte für das markierte Skript."
        );
        return;
      }
      let firstUnfixedCard = this.getFirstUnfixedCard(this.initSaveSelect);
      this.unsaveAllCards();
      this.cardList[firstUnfixedCard].merkenActive = true;
      this.cardList[firstUnfixedCard].cardFlipped = true;
      this.lastAction = "Merkzeiger auf erste Karte " + this.initSaveSelect;
    },
    //CARD 3
    moveReadToNext() {
      //console.log("moveReadToNext");
      if (!this.moveReadSelect) {
        this.createCardError(
          "card3",
          "Bitte setzen sie eine Richtung für das markierte Skript."
        );
        return;
      }
      let positionCurrentReadCard = this.getPositionCurrentReadCard();
      if (positionCurrentReadCard == -1) {
        this.createCardError(
          "card3",
          "Sie versuchen auf eine Karte zuzugreifen, die in der Kartenliste nicht existiert. Überlegen Sie sich, in welche Richtung Sie den Zeiger bewegen müssen."
        );
        this.assignment2Results.readOutOfBoundsErrors++;
        // this.cardList[0].lesenActive = true;
        // this.tickPosition = 0;
        return;
      }
      let nextReadCard = this.getNextReadCard(
        positionCurrentReadCard,
        this.moveReadSelect
      );
      if (nextReadCard == 5) {
        this.createCardError(
          "card3",
          "Sie versuchen auf eine Karte zuzugreifen, die in der Kartenliste nicht existiert. Überlegen Sie sich, in welche Richtung Sie den Zeiger bewegen müssen."
        );
        this.assignment2Results.readOutOfBoundsErrors++;
        // this.cardList[4].lesenActive = true;
        // this.tickPosition = 4;
        return;
      }
      //console.log("nextReadCard: " + nextReadCard);
      this.unreadAllCards();
      this.cardList[nextReadCard].lesenActive = true;
      this.flipCard(this.cardList[positionCurrentReadCard]);
      this.flipCard(this.cardList[nextReadCard]);
      this.lastAction = "Lesezeiger eine Position nach " + this.moveReadSelect;
    },
    getPositionCurrentReadCard() {
      for (let i = 0; i < this.cardList.length; i++) {
        if (this.cardList[i].lesenActive) return i;
      }
      return -1;
    },
    getPositionCurrentSaveCard() {
      for (let i = 0; i < this.cardList.length; i++) {
        if (this.cardList[i].merkenActive) return i;
      }
      return -1;
    },
    getNextReadCard(positionCurrentReadCard, direction) {
      if (direction === "links")
        return (positionCurrentReadCard - 1) % (this.cardList.length - 1);
      else if (direction === "rechts") return positionCurrentReadCard + 1;
    },
    //CARD 4
    moveSaveToRead() {
      //console.log("moveSaveToRead");
      let positionReadCard = this.getPositionCurrentReadCard();
      if (positionReadCard == -1) {
        this.createCardError(
          "card4",
          "Sie haben noch keine Karte gelesen. Versuche Sie ihren Algorithmus anzupassen."
        );
        this.assignment2Results.usedReadWithoutSettingReadErrors++;
        return;
      }
      let positionSaveCard = this.getPositionCurrentSaveCard();
      if (positionSaveCard == -1) {
        this.createCardError(
          "card4",
          "Sie haben noch keine Karte gemerkt. Versuche Sie ihren Algorithmus anzupassen."
        );
        this.assignment2Results.usedSaveWithoutSettingSaveErrors++;
        return;
      }
      let currentReadValue = this.cardList[positionReadCard].number;
      let currentSaveValue = this.cardList[positionSaveCard].number;
      //console.log('currentReadValue: ' + currentReadValue + " currentSaveValue: " + currentSaveValue )
      if (this.compareCardsSelect == "kleiner") {
        if (currentReadValue < currentSaveValue) {
          this.unsaveAllCards();
          this.cardList[positionReadCard].merkenActive = true; //move merkzeiger to position of read card
          this.flipCard(this.cardList[positionSaveCard]); //unflip old saved card
          this.lastAction =
            currentReadValue +
            " ist kleiner als " +
            currentSaveValue +
            ": Setze Merkzeiger auf Lesezeiger-Position";
        } else {
          this.lastAction =
            currentReadValue +
            " ist nicht kleiner als " +
            currentSaveValue +
            ": Merkzeiger bleibt auf aktueller Position";
        }
      } else if (this.compareCardsSelect == "größer") {
        if (currentReadValue > currentSaveValue) {
          this.unsaveAllCards();
          this.cardList[positionReadCard].merkenActive = true; //move merkzeiger to position of read card
          this.flipCard(this.cardList[positionSaveCard]); //unflip old saved card
          this.lastAction =
            currentReadValue +
            " ist größer als " +
            currentSaveValue +
            ": Setze Merkzeiger auf Lesezeiger-Position";
        } else {
          this.lastAction =
            currentReadValue +
            " ist nicht größer als " +
            currentSaveValue +
            ": Merkzeiger bleibt auf aktueller Position";
        }
      }
    },
    //CARD 5
    jumpToStep3() {
      //console.log("jumpToStep3");
      let positionReadCard = this.getPositionCurrentReadCard();
      if (positionReadCard == -1) {
        this.createCardError(
          "card5",
          "Sie haben noch keine Karte gelesen. Versuche Sie ihren Algorithmus anzupassen."
        );
        this.assignment2Results.usedReadWithoutSettingReadErrors++;
        return;
      }
      let positionSaveCard = this.getPositionCurrentSaveCard();
      if (positionSaveCard == -1) {
        this.createCardError(
          "card5",
          "Sie haben noch keine Karte gemerkt. Versuche Sie ihren Algorithmus anzupassen."
        );
        this.assignment2Results.usedSaveWithoutSettingSaveErrors++;
        return;
      }

      let nextReadCard = this.getNextReadCard(
        positionReadCard,
        this.jumpReadSelect
      );
      if (this.jumpReadSelect === "links") {
        if (positionReadCard == 0 || this.cardList[nextReadCard].fixActive) {
          this.increaseTickPosition();
          this.lastAction =
            "Der Lesezeiger befindet sich auf Position " +
            (positionReadCard + 1) +
            ": Gehe weiter zu Schritt (" +
            this.tickPosition +
            ".)";
        } else {
          this.tickPosition = 3;
          this.lastAction =
            "Der Lesezeiger befindet sich auf Position " +
            (positionReadCard + 1) +
            ": Springe zu Schritt (3.)";
        }
      } else if (this.jumpReadSelect === "rechts") {
        //console.log('position read card' + positionReadCard)
        if (positionReadCard == 4 || this.cardList[nextReadCard].fixActive) {
          this.increaseTickPosition();
          this.lastAction =
            "Der Lesezeiger befindet sich auf Position " +
            (positionReadCard + 1) +
            ": Gehe weiter zu Schritt (" +
            this.tickPosition +
            ".)";
        } else {
          this.tickPosition = 3;
          this.lastAction =
            "Der Lesezeiger befindet sich auf Position " +
            (positionReadCard + 1) +
            ": Springe zu Schritt (3.)";
        }
      } else {
        this.createCardError(
          "card5",
          "Bitte setzen sie eine Richtung für das markierte Skript."
        );
        return;
      }
    },
    //CARD 6
    swapSaveWithFirst() {
      //console.log("swapSaveWithFirst");
      if (!this.swapSaveSelect) {
        this.createCardError(
          "card6",
          "Bitte setzen sie eine zu tauschende Karte im markierten Skript."
        );
        return;
      }
      let positionFirstUnfixedCard = this.getFirstUnfixedCard(
        this.swapSaveSelect
      );
      let positionSaveCard = this.getPositionCurrentSaveCard();
      if (positionSaveCard == -1) {
        this.createCardError(
          "card6",
          "Ein Kartentausch benötigt immer eine gemerkte Karte mit der die erste Karte des unsortierten Bereichs getauscht wird. In Ihrem aktuellen Algorithmus haben Sie noch keine Karte aus der Liste für einen Tausch vorgemerkt."
        );
        this.assignment2Results.noCardSavedBeforeSwapErrors++;
        return;
      }

      let tmpNumber = this.cardList[positionFirstUnfixedCard].number;
      //console.log(tmpNumber + " >?<" + this.cardList[positionSaveCard].number)
      this.cardList[positionFirstUnfixedCard].number =
        this.cardList[positionSaveCard].number;
      this.cardList[positionSaveCard].number = tmpNumber;
      this.lastAction =
        "Tausche Karte " +
        this.cardList[positionFirstUnfixedCard].number +
        " mit Karte " +
        this.cardList[positionSaveCard].number;
    },
    //CARD 7
    fixSortedCard() {
      //console.log("fixSortedCard");
      if (!this.fixCardSelect) {
        this.createCardError(
          "card7",
          "Bitte setzen sie eine Richtung für das markierte Skript."
        );
        return;
      }
      let firstUnfixedCard = this.getFirstUnfixedCard(this.fixCardSelect);
      this.cardList[firstUnfixedCard].fixActive = true;
      this.cardsFixed++;
      this.lastAction = "Fixiere die Karte ganz " + this.fixCardSelect;
    },
    //CARD 8
    jumpToStep1() {
      let deltaFixedCards = 5 - this.cardsFixed;
      if (deltaFixedCards >= 2) {
        this.tickPosition = 1;
        this.lastAction =
          "Es gibt mindestens zwei nichtfixierte Karten: Springe zu Schritt (1.)";
      } else {
        this.increaseTickPosition();
        this.lastAction =
          "Es gibt nur mehr eine nichtfixierte Karte: Gehe weiter zu Schritt (" +
          this.tickPosition +
          ".)";
      }
    },
    checkSortedFinished() {
      for (let i = 0; i < this.cardList.length - 1; i++) {
        if (!(this.cardList[i].number < this.cardList[i + 1].number))
          return false;
      }
      return true;
    },
    //CARD 9
    fixLastCard() {
      if (this.cardsFixed == 5) {
        this.createCardError("card9", "Sie haben bereits alle Karten fixiert!");
        this.assignment2Results.fixedAllCardsErrors++;
      }
      if (this.checkSortedFinished()) {
        this.isAssignmentFinished = true;
        this.tick = false;
        let firstUnfixedCard = this.getFirstUnfixedCard(this.fixCardSelect);
        this.cardList[firstUnfixedCard].fixActive = true;
        this.cardsFixed++;
        this.feedbackMessage =
          "Herzlichen Glückwunsch. Sie haben die Kartenliste erfolgreich sortiert. Den von Ihnen erstellten Algorithmus nennt man Selection-Sort. Im nächsten Abschnitt dieses Moduls wollen wir Ihnen weitere Algorithmen zum Sortieren von Listen vorstellen.";
        this.assignment2Results.endTime = new Date();
        this.assignment2Results.assignment2Finished = true;
      } else {
        this.createCardError(
          "card9",
          "Leider konnten Sie die Zahlenliste mit Ihren Algorithmus nicht richtig sortieren. Überlegen Sie sich, welche Operationen Sie im vorigen Beispiel durchgeführt haben um die Zahlenliste zu sortieren. Ordnen Sie die Skripte im Algorithmus entsprechend an."
        );
        this.assignment2Results.finishedWithWrongOrderErrors++;
      }
    },
  },
};
</script>

<style scoped>
#assignment-container {
  display: flex;
  flex-direction: row;
  min-height: 480px;
}

/** Cards style */
#card-container {
  border: 2px solid black;
  border-radius: 5px;
  background-color: #f9f9f9;
  flex: 0 0 25%;
  overflow: scroll;
  overflow-x: hidden;
  padding-right: 10px;
  max-height: 480px;
}

.card {
  border: 1px solid #006bac;
  color: black;
  cursor: grab;
  display: inline-block;
  margin: 5px;
  padding: 0 5px 0 5px;
  border-radius: 5px;
  font-size: 0.8em;
  line-height: 1.5em;
}

.card-error {
  border: 3px solid red;
}

.lesezeiger-word {
  color: #f28705;
}

.merkzeiger-word {
  color: #1ea983;
}

.custom-select {
  border: none;
  border-bottom: 2px solid #006bac;
  padding-left: 1em;
  padding-right: 1em;
  color: black;
  cursor: pointer;
  background-color: white;
}

.custom-select:hover {
  border-bottom: 2px solid #00446e;
}

/** Algorithm style */
#algorithm-container {
  padding-left: 20px;
  flex: 0 0 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.drop-slot-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: center;
  border: 1px solid darkgray;
  border-radius: 5px;
  margin: 1px;
  padding-left: 4px;
}

.drop-slot {
  background-color: white;
  border: 1px solid darkgray;
  min-height: 40px;
  width: 100%;
  margin-left: 4px;
}

.drop-slot-active {
  border: 3px solid #006bac;
}

.drop-slot-error {
  border: 3px solid red;
}

/** Algorithm buttons */
.feedback-hint{
  cursor: pointer;
}
.feedback-hint:hover{
  text-decoration: underline;
}

#algorithm-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 0 0 15%;
}

.button {
  background-color: #006bac;
  border-radius: 5px;
  color: white;
  font-size: 1.8em;
  text-align: center;
  text-decoration: none;
  padding: 0.2em;
  cursor: pointer;
  margin: 0.1em;
}

.button:hover {
  background-color: #00446e;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

/** Number list */
.number-list-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
}

li {
  list-style-type: none;
  padding: 10px 10px;
  transition: all 0.3s ease;
}

.number-list-card {
  display: flex;
  justify-content: center;
  align-items: center;
  color: black;
  height: 80px;
  width: 80px;
  border: 2px solid black;
  border-radius: 10px;
  background-color: #006bac;
}

.cardFlipped {
  background-color: white;
}

.cardFixed {
  background-color: lightblue;
  color: darkgray;
}

.arrow-container {
  width: 80px;
  height: 40px;
  display: flex;
  justify-content: space-around;
}

/** Parameters */
#trackedParameters {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1em;
}

/* scrollbar for Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #006bac lightblue;
}

/* scrollbar for Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
}

*::-webkit-scrollbar-track {
  background: lightblue;
}

*::-webkit-scrollbar-thumb {
  background-color: #006bac;
  border-radius: 20px;
  border: 3px solid lightblue;
}
</style>
