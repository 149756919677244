<template>
  <div class="container">
    <AssignmentbarSorting1
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    />

    <div class="sorting-algorithm-container" v-if="!showFinishSortingContainer">
    <div class="animate glow row">
      <h2>Weitere Sortieralgorithmen</h2>

      <p>
        Um Listen zu sortieren, wurden in der Informatik verschiedene
        Sortieralgorithmen entwickelt. Alle haben das Ziel, eine Liste von
        Elementen in eine geordnete Reihenfolge zu bringen. Wie sie dabei
        vorgehen, unterscheidet sich oft aber stark und daher sind manche
        Algorithmen besser für einen bestimmten Anwendungsfall geeignet als
        andere. In diesem Abschnitt möchten wir Ihnen einige dieser
        Sortieralgorithmen vorstellen.
      </p>
    </div>

    <div class="animate glow row">
      <h3>Selectionsort</h3>
      <p>
        Dies ist der Algorithmus, den Sie in der gerade gelösten Aufgabe
        erarbeitet haben. Beim Selectionsort wird die Liste von Objekten nach
        dem Objekt mit dem kleinsten Wert durchsucht und dieser mit dem
        unsortierten Objekt an erster Stelle ausgetauscht. Das Objekt mit dem
        kleinsten Wert wird als sortiert markiert und nun wird das selbe
        Prozedere immer wieder auf alle übrigen unsortierten Elemente
        angewendet, bis alle Elemente sortiert sind.
      </p>

      <SelectionSortVis n="32" stepDuration="200" />
    </div>

    <div class="animate glow row">
      <h3>Insertionsort</h3>
      <p>
        Insertionsort entnimmt nach und nach der unsortierten Eingabefolge das
        erste Element und fügt es an richtiger Stelle in die (anfangs leere)
        sortierte Ausgabefolge ein. Bei der wachsenden sortierten Ausgabefolge
        müssen daraufhin alle Elemente hinter dem neu einsortierten Element
        verschoben werden. Dies ist die eigentlich aufwändige Operation des
        Insertionsorts.
      </p>

      <InsertionSortVis n="32" stepDuration="200" />
    </div>

    <div class="animate glow row">
      <h3>Bubblesort</h3>
      <p>
        Bei diesem Algorithmus wird eine Eingabefolge immer paarweise von Anfang
        bis Ende in einer sogenannten „Bubble-Phase“ durchlaufen. Das erste
        Element wird mit dem direkten Nachbarn verglichen und falls die beiden
        Elemente falsch angeordnet sind, werden sie ganz einfach miteinander
        vertauscht. Danach wird direkt das nächste Paar miteinander verglichen,
        bis die gesamte Liste einmal durchlaufen wurde. Die Bubble-Phase wird so
        oft wiederholt, bis die ganze Liste vollständig sortiert ist. Die
        größeren Elemente steigen dabei auf wie Blasen im Wasser - daher kommt
        der Name Bubblesort.
      </p>

      <BubbleSortVis n="32" stepDuration="200" />
    </div>

    <div class="animate glow row">
      <h3>Mergesort</h3>
      <p>
        Mergesort betrachtet die zu sortierenden Daten als Liste und zerlegt sie
        in kleinere Listen, die jede für sich sortiert werden. Die kleinen
        sortierten Listen werden dann im Reißverschlussverfahren zu größeren
        sortierten Listen zusammengefügt (englisch: merged), bis eine sortierte
        Gesamtliste erreicht ist.
      </p>

      <MergeSortVis n="32" stepDuration="200" />
    </div>

    <div class="btn-container-right">
      <div class="custom-btn" @click="showFinishSortingContainerClicked()">
        Weiter
      </div>
    </div>
  </div>

    <div class="finish-sorting-container" v-if="showFinishSortingContainer">
      <h3>Herzlichen Glückwunsch!</h3>
      Sie haben „Sortieren mit System“ nun erfolgreich abgeschlossen. In diesem
      Modul haben Sie kennen gelernt wie sich die menschliche Herangehensweise
      beim Sortieren von der eines Computers unterscheidet. Dazu haben Sie einen
      einfachen Algorithmus erstellt und damit eine Zahlenliste sortiert. Solche
      Sortieralgorithmen sind in der Informatik weit verbreitet und lassen sich
      auch problemlos auf größere Zahlenlisten anwenden, die für Menschen zu
      unübersichtlich wären.

      <div class="user-study-comment">
        Im Rahmen der User Study haben wir einen kurzen Fragebogen für dieses
        Modul vorbereitet.
        <strong
          >Bitte öffnen und beantworten Sie diesen, bevor Sie mit anderen Teilen
          des OSA fortfahren.
        </strong>
        Nur eine Bearbeitung unmittelbar nach Durchführung des Moduls erlaubt es
        unverfälschte Antworten zu erfassen.<br/>

        Beantworten Sie diesen Fragebogen nur beim ersten Abschluss des Experimentiermoduls „Sortieren mit System“.  
        Sollten Sie diesen Fragebogen bereits einmal bearbeitet haben, können Sie jetzt zum OSA zurückkehren.

        <div class="" style="display: flex; justify-content: center">
          <a href="https://survey.media.tuwien.ac.at/index.php/835736?lang=de" target="_blank"
            ><div class="custom-btn" @click="questionnaireOpened = true">
              Zum Fragebogen
            </div></a
          >
        </div>
      </div>

      <div class="btn-container-right">
        <div
          class="custom-btn"
          @click="finishConclusion()"
        >
          Modul abschließen
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignmentbarSorting1 from "@/components/inf/sorting/Assignment1/AssignmentbarSorting1.vue";
import SelectionSortVis from "@/components/inf/sorting/visualizations/SelectionSortVis.vue";
import InsertionSortVis from "@/components/inf/sorting/visualizations/InsertionSortVis.vue";
import BubbleSortVis from "@/components/inf/sorting/visualizations/BubbleSortVis.vue";
import MergeSortVis from "@/components/inf/sorting/visualizations/MergeSortVis.vue";
import * as scrolling from "@/helpers/scroll-helper.js";

export default {
  name: "SortingConclusion",
  components: {
    AssignmentbarSorting1,
    SelectionSortVis,
    InsertionSortVis,
    BubbleSortVis,
    MergeSortVis,
  },
  emits: ["sorting-conclusion-finished"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      showFinishSortingContainer: false,
      questionnaireOpened: false,
    };
  },
  methods: {
    async showFinishSortingContainerClicked() {
      this.showFinishSortingContainer = true;
      await this.$nextTick();
      setTimeout(function () {
        scrolling.scrollToElementByClassName("finish-sorting-container", {
          behavior: "smooth",
        });
      }, 80);
    },
    finishConclusion() {
      //if (this.questionnaireOpened) {
        this.$emit("sorting-conclusion-finished");
      //}
    },
  },
};
</script>

<style scoped>
.btn-container-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 20px;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
p{
  line-height: 1.3;
}
</style>
