<template>
  <div class="feedback-container">
    <div class="feedback-field" v-if="!showLastAction">
      <p>{{ feedbackMessage }}</p>
      <div class="button-container">
        <div
          class="custom-btn"
          @click="stopAlgorithm"
          v-if="!isAssignmentFinished"
        >
            <i class="bi bi-stop-circle" style="padding-right:1em"></i>Stop
      </div>
      </div>
    </div>
    <div class="last-action-container" v-else>
      <p class="last-action-text">{{ lastAction }}</p>
    </div>
    <div class="button-container">
      <!--<button class="custom-btn" v-if="!lastActionValid" @click="undoLastAction">OK</button>-->
      <div
        class="custom-btn"
        v-if="isAssignmentFinished"
        @click="finishAssignmentBtnClicked()"
      >
        Weiter
    </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    feedbackMessage: String,
    lastAction: String,
    showLastAction: Boolean,
    isAssignmentFinished: Boolean,
  },
  emits: ["stop-algorithm", "finish-assignment"],
  data() {
    return {};
  },
  methods: {
    stopAlgorithm() {
      this.$emit("stop-algorithm");
    },
    finishAssignmentBtnClicked() {
      this.$emit("finish-assignment");
    },
  },
};
</script>
<style scoped>

.feedback-container{
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
}

.feedback-field {
  height: 100px;
  width: 100%;
}

.last-action-container {
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.last-action-header {
  font-size: 1em;
}
.last-action-text {
  font-size: 1.4em;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
