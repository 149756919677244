export {
  performAction,
  resetSelectionSort,
  getFeedbackMessage,
  resetConsecutiveErrors,
};

// IMPORT Feedback
const invalidResponses = require("@/scripts/sorting/a1/invalidResponses.json");
// IMPORT REFERENCE SOLUTION PATHS
const solutionPath1 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath1.json");
const solutionPath2 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath2.json");
const solutionPath3 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath3.json");
const solutionPath4 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath4.json");

// var lastError = null;
var consecutiveErrors = 0;
var feedbackMessage = "";

var cardStates = null;
var performedAction = null;
var targetAction = null;
var validActions = 0;
var selectedSolutionPath;

function performAction(
  performedAction1,
  targetAction1,
  cardStates1,
  selectedSolutionPath1
) {
  performedAction = performedAction1;
  targetAction = targetAction1;
  cardStates = cardStates1;
  selectedSolutionPath = selectedSolutionPath1;

  consecutiveErrors = Math.min(4, consecutiveErrors + 1);
  createFeedbackMessage();
  checkSpecialCases();
}

/**
 * Returns the string from correctResponses matching with the current step of the solution.
 * @returns {String} response of current step in selected solution path
 */
function getFeedbackMessage() {
  let patternCard1 = "PLACEHOLDER_CARD1";
  let patternCard2 = "PLACEHOLDER_CARD2";

  let tmpMsg = feedbackMessage.replace(patternCard1, targetAction[0].card1);
  tmpMsg = tmpMsg.replace(patternCard2, targetAction[0].card2);

  feedbackMessage = "";
  return tmpMsg;
}

function resetSelectionSort() {
  consecutiveErrors = 0;
  feedbackMessage = "";

  cardStates = null;
  performedAction = null;
  targetAction = null;
  validActions = 0;
}

function resetConsecutiveErrors() {
  consecutiveErrors = 0;
  validActions++;
}

function createFeedbackMessage() {
  //check if solution needs to be displayed
  if (consecutiveErrors == 4) {// 4 consecutive errors --> show solution
    switch (targetAction[0].tool) {
      case "L":
        if (validActions == 0) {
          feedbackMessage = invalidResponses.solutions.read.error4FirstAction;
        } else {
          feedbackMessage = invalidResponses.solutions.read.error4OtherAction;
        }
        return;
      case "M":
        feedbackMessage = invalidResponses.solutions.save.error4;
        return;
      case "F":
        feedbackMessage = invalidResponses.solutions.fix.error4;
        return;
      case "T":
        feedbackMessage = invalidResponses.solutions.swap.error4;
        return;
      default:
        throw new Error("ERROR: No valid action given!");
    }
  }
  if(validActions < 4){
    //step 4
    if(performedAction.tool == 'L'){
      //Read (L) performed
      if(consecutiveErrors == 1){
        feedbackMessage = invalidResponses.step4Errors.readPerformed.error1;
        return;
      }
      else if(consecutiveErrors == 2){
        feedbackMessage = invalidResponses.step4Errors.readPerformed.error2;
        return;
      } else if(consecutiveErrors == 3){
        feedbackMessage = invalidResponses.step4Errors.readPerformed.error3;
        return;
      }
    }
    if(performedAction.tool == 'M'){
      //Save (M) performed
      if(consecutiveErrors == 1){
        feedbackMessage = invalidResponses.step4Errors.savePerformed.error1;
        return;
      }
      else if(consecutiveErrors == 2){
        feedbackMessage = invalidResponses.step4Errors.savePerformed.error2;
        return;
      } else if(consecutiveErrors == 3){
        feedbackMessage = invalidResponses.step4Errors.savePerformed.error3;
        return;
      }
    }
    //if performed action is fix (F) or swap (T) do nothing and go to
  }
  

  switch (performedAction.tool) {
    //in this switch statement the length targetAction can only be 1
    //because step 1-4 were already handled in first if-block of this function
    case "L":
      handleReadPerformedAction();
      break;
    case "M":
      handleSavePerformedAction();
      break;
    case "F":
      handleFixPerformedAction();
      break;
    case "T":
      handleSwapPerformedAction();
      break;
    default:
      throw new Error("ERROR: No valid action given!");
  }
}

function handleReadPerformedAction() {
  if (targetAction[0].tool == "L") {
    //SOLL-Lesen && IST-Lesen Fehler
    if (validActions == 0) {
      //no valid actions performed yet
      if (consecutiveErrors == 1) {
        feedbackMessage = invalidResponses.targetPerformedMatching.read.error1;
      } else if (consecutiveErrors == 2) {
        feedbackMessage =
          invalidResponses.targetPerformedMatching.read.error2Text1;
      } else if (consecutiveErrors == 3) {
        feedbackMessage =
          invalidResponses.targetPerformedMatching.read.error3Text1;
      }
    } else {
      //first valid action was already performed
      if (consecutiveErrors == 1) {
        feedbackMessage = invalidResponses.targetPerformedMatching.read.error1;
      } else if (consecutiveErrors == 2) {
        feedbackMessage =
          invalidResponses.targetPerformedMatching.read.error2Text2;
      } else if (consecutiveErrors == 3) {
        //TODO: Ist Position X gleich der ersten nicht fixierten Position in der Reihe?
        feedbackMessage =
          invalidResponses.targetPerformedMatching.read.error3Text2;
      }
    }
  } else {
    //SOLL-OTHER && IST-Lesen
    if (consecutiveErrors == 1) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.readPerformed.error1;
    } else if (consecutiveErrors == 2) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.readPerformed.error2;
    } else if (consecutiveErrors == 3) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.readPerformed.error3;
    }
  }
}

function handleSavePerformedAction() {
  if (targetAction[0].tool == "M") {
    //SOLL-Merken && IST-merken gleich
    if (consecutiveErrors == 1) {
      feedbackMessage = invalidResponses.targetPerformedMatching.save.error1;
    } else if (consecutiveErrors == 2) {
      feedbackMessage = invalidResponses.targetPerformedMatching.save.error2;
    } else if (consecutiveErrors == 3) {
      feedbackMessage = invalidResponses.targetPerformedMatching.save.error3;
    }
  } else {
    //SOLL-OTHER && IST-Merken

    if (consecutiveErrors == 1) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.savePerformed.error1;
    } else if (consecutiveErrors == 2) {
      if (cardStates[performedAction.card1 - 1].saveActive) {
        feedbackMessage =
          invalidResponses.targetPerformedNotMatching.savePerformed.error2Text2;
      } else {
        feedbackMessage =
          invalidResponses.targetPerformedNotMatching.savePerformed.error2Text2;
      }
    } else if (consecutiveErrors == 3) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.savePerformed.error3;
    }
  }
}

function handleFixPerformedAction() {
  if (targetAction[0].tool == "F") {
    //SOLL-Fixieren && IST-Fixieren gleich
    if (cardStates[performedAction.card1 - 1].fixActive) {
      //card is already fixed
      if (consecutiveErrors == 1) {
        feedbackMessage = invalidResponses.targetPerformedMatching.fix.error1;
      } else if (consecutiveErrors == 2) {
        feedbackMessage =
          invalidResponses.targetPerformedMatching.fix.error2Text2;
      } else if (consecutiveErrors == 3) {
        feedbackMessage =
          invalidResponses.targetPerformedMatching.fix.error3Text2;
      }
    } else {
      //card is not fixed
      if (consecutiveErrors == 1) {
        feedbackMessage = invalidResponses.targetPerformedMatching.fix.error1;
      } else if (consecutiveErrors == 2) {
        feedbackMessage =
          invalidResponses.targetPerformedMatching.fix.error2Text1;
      } else if (consecutiveErrors == 3) {
        feedbackMessage =
          invalidResponses.targetPerformedMatching.fix.error3Text1;
      }
    }
  } else {
    //SOLL-OTHER && IST-Fixieren
    if (consecutiveErrors == 1) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.fixPerformed.error1;
    } else if (consecutiveErrors == 2) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.fixPerformed.error2;
    } else if (consecutiveErrors == 3) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.fixPerformed.error3;
    }
  }
}

function handleSwapPerformedAction() {
  if (targetAction[0].tool == "T") {
    //SOLL-Fixieren && IST-Fixieren gleich
    if (consecutiveErrors == 1) {
      feedbackMessage = invalidResponses.targetPerformedMatching.swap.error1;
    } else if (consecutiveErrors == 2) {
      feedbackMessage = invalidResponses.targetPerformedMatching.swap.error2;
    } else if (consecutiveErrors == 3) {
      feedbackMessage = invalidResponses.targetPerformedMatching.swap.error3;
    }
  } else {
    //SOLL-OTHER && IST-Fixieren
    let specialCase = cardStates.forEach((card) => {
      let fixedCards = 0;
      if (card.fixActive) {
        fixedCards++;
      }
      if (fixedCards == 4) {
        return true;
      } else {
        return false;
      }
    });

    if (specialCase) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.swapPerformed.specialCase;
    } else if (consecutiveErrors == 1) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.swapPerformed.error1;
    } else if (consecutiveErrors == 2) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.swapPerformed.error2;
    } else if (consecutiveErrors == 3) {
      feedbackMessage =
        invalidResponses.targetPerformedNotMatching.swapPerformed.error3;
    }
  }
}

function checkSpecialCases() {
 // console.log('consecutive Errors: ' + consecutiveErrors)
  if (consecutiveErrors == 1) {
    //first error in current step
    let actionAfterNext;
    //console.log('selected solution path: ' + selectedSolutionPath);
    switch (selectedSolutionPath) {
      case 1:
        if (
          solutionPath1.find((step) => step.step === validActions + 1)
            .difficultStep
        ) {
          //next step is difficult
          actionAfterNext = solutionPath1.find(
            (step) => step.step === validActions + 2
          ).action;
          if (compareActions(performedAction, actionAfterNext)) {
            //user skipped difficult step and performed the action after next
            feedbackMessage = invalidResponses.specialCases.saveLastCard;
          }
        }
        break;
      case 2:
        solutionPath2.find((step) => step.step === validActions + 1).difficultStep;
        break;
      case 3:
        solutionPath3.find((step) => step.step === validActions + 1).difficultStep;
        break;
      case 4:
        //console.log('validActions: ' + validActions);
       // console.log('difficult step?: ' + solutionPath4.find((step) => step.step === validActions + 1).difficultStep)
        if (
          solutionPath4.find((step) => step.step === validActions + 1)
            .difficultStep
        ) {
          //next step is difficult
          actionAfterNext = solutionPath4.find(
            (step) => step.step === validActions + 2
          ).action;
          if (compareActions(performedAction, actionAfterNext)) {
            //user skipped difficult step and performed the action after next
            feedbackMessage = invalidResponses.specialCases.saveLastCard;
          }
        }
        break;
      default:
      //do nothing
    }
  }
}

/*console.log(targetAction);
  if (consecutiveErrors == 0) {
    //no consecutive errors made so far
    if(targetAction[0].tool == 'M' && (targetAction[0].card1 == '1' || targetAction[0].card1 == '5')){
      // target action is save (merken) on first/last card - depends on selected solution path
      checkSaveLastCard(); 
    }
  }
}

function checkSaveLastCard() {
  if(performedAction.tool == 'T' && (performedAction.card2 == 5)){
    if(performedAction.card1 == 3 || performedAction.card1 == 4){
      //swapped last card with position 3 or 4
      feedbackMessage = invalidResponses.
    }
  }*/

function compareActions(action1, action2) {
  if (
    action1.tool == action2.tool &&
    action1.card1 == action2.card1 &&
    action1.card2 == action2.card2
  ) {
    return true;
  }
  return false;
}
