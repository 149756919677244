<template>
    <svg :class="color" viewBox="0 0 34 47.211" >
         <path d="M12.082,22.948v24.66a1.853,1.853,0,0,0,1.853,1.853h8.646a1.853,1.853,0,0,0,1.853-1.853V22.948h7.111a3.706,3.706,0,0,0,2.62-6.326L20.878,3.335a3.705,3.705,0,0,0-5.24,0L2.351,16.622a3.706,3.706,0,0,0,2.62,6.326Z" transform="translate(-1.258 -2.25)" />
    </svg>
</template>

<script>
export default {
   name: 'ArrowIcon',

   props: {
       color: String //green or orange
   }
}
</script>

<style scoped>
.green {
    fill: #1EA983
}

.orange {
    fill: #f28705
}

.invisible {
    visibility: hidden;
}

</style>