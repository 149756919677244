import store from "../../../store";

export { logAction, finishLogging };

// let obj = {
//     "personId": 128394, (by strapi)
//     "actions": [
//         "date": "2022-01-03T00:00:00.000Z",
//         "stepId": 1,
//         "solutionPaths": [
//             1,
//             2
//         ],
//         "targetActions": [
//             {
//                 "targetTool": "L",
//                 "targetCards": {
//                     "card1": 1,
//                     "card2": null
//                 }
//             }
//         ],
//         "performedAction": {
//             "usedTool": "L",
//             "usedCards": {
//                 "card1": 1,
//                 "card2": null
//             }
//         },
//         "comment": null
//     ]
// }


/**
 * 
 * @param {Object} targetActions possible target actions of current step
 * @param {Object} performedAction performed action by user
 */
function logAction(solutionPaths, targetActions, performedAction) {
    const date = new Date();
    let actionObj = { date, solutionPaths, targetActions, performedAction };
    store.commit('sortingA1AppendAction', actionObj);
}


function finishLogging() {
    store.commit('sortingA1LogPost');
}