import * as valid from "@/scripts/sorting/a1/sorting-valid-processing.js";
import * as invalid from "@/scripts/sorting/a1/sorting-invalid-processing.js";
import * as logging from "@/scripts/sorting/a1/a1-logging.js";
import store from "@/store";

export { actionPerformed, resetSelectionSort, getFeedbackMessage, isSortingFinished};

// IMPORT REFERENCE SOLUTION PATHS
const solutionPath1 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath1.json");
const solutionPath2 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath2.json");
const solutionPath3 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath3.json");
const solutionPath4 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath4.json");


var validActionsPerformed = 0;
var selectedReadDirection;
var selectedFixDirection;
var selectedSolutionPath = 0;
var feedbackMessage = "";
var sortingFinished = false;

/**
 * Entry point to the selection sort algorithmn from vue-frontend. Handles the action perfomed by the user.
 * @param {Object} action performed action of the user (card1 is always the card with the smaller id)
 */
function actionPerformed(action, cardStates) {

  logging.logAction(selectedSolutionPath ,getTargetActions(), action);

  if (sortingFinished) {
    feedbackMessage = "Sie haben das Beispiel bereits richtig fertiggestellt.";
    return;
  }

  if (isPerformedActionValid(action)) {
    //VALID ACTION
    validActionsPerformed++;
    if (validActionsPerformed == 1) {
      selectedReadDirection = valid.getReadDirection(action);
    }
    if (validActionsPerformed == 4) {
      selectedFixDirection = valid.getFixDirection(action, selectedReadDirection);
      setSolutionPath();
    }
    invalid.resetConsecutiveErrors();
    valid.performAction(selectedSolutionPath);
    feedbackMessage = valid.getFeedbackMessage();
    return true; //valid action performed
  } else {
    // INVALID ACTION
    invalid.performAction(action, getTargetActions(), cardStates, selectedSolutionPath);
    feedbackMessage = invalid.getFeedbackMessage();
    return false; //invalid action performed
  }
}

function resetSelectionSort(){
  validActionsPerformed = 0;
  selectedReadDirection = 0;
  selectedFixDirection = 0;
  selectedSolutionPath = 0;
  feedbackMessage = "";
  sortingFinished = false;

  store.commit('sortingA1Reset');
  valid.resetSelectionSort();
  invalid.resetSelectionSort();
}

/**
 * @returns feedback mesage of last action
 */
function getFeedbackMessage() {
  return feedbackMessage;
}

/**
 * check if all steps have been successfully performed
 * @returns true if sorting is finished 
 */
function isSortingFinished() {
  return valid.isSortingFinished();
}

/**
 *
 * @param {Action} performedAction action performed by the user
 * @returns true if the performed action matches a target action
 */
function isPerformedActionValid(performedAction) {
  let targetActions = getTargetActions();
  let performedActionValid = false;

  targetActions.forEach((action) => {
    if (action.tool == performedAction.tool && action.card1 == performedAction.card1 && action.card2 == performedAction.card2) {
      performedActionValid = true;
    }
  });
  return performedActionValid;
}

/**
 *
 * @returns list of all valid actions
 */
function getTargetActions() {
  let validTargetActions = [];

  if (!selectedReadDirection) {
    // read direction is not selected --> 4 possible solution paths are open
    validTargetActions.push(solutionPath1.find((step) => step.step === validActionsPerformed + 1).action);
    validTargetActions.push(solutionPath2.find((step) => step.step === validActionsPerformed + 1).action);
    validTargetActions.push(solutionPath3.find((step) => step.step === validActionsPerformed + 1).action);
    validTargetActions.push(solutionPath4.find((step) => step.step === validActionsPerformed + 1).action);
  } else if (selectedReadDirection && !selectedFixDirection) {
    // read direction selected and fix direction not selected --> 2 possible solution paths open
    if (selectedReadDirection == "L") {
      validTargetActions.push(solutionPath1.find((step) => step.step === validActionsPerformed + 1).action);
      validTargetActions.push(solutionPath2.find((step) => step.step === validActionsPerformed + 1).action);
    }
    if (selectedReadDirection == "R") {
      validTargetActions.push(solutionPath3.find((step) => step.step === validActionsPerformed + 1).action);
      validTargetActions.push(solutionPath4.find((step) => step.step === validActionsPerformed + 1).action);
    }
  } else if (selectedReadDirection && selectedFixDirection) {
    // read direction and fix direction are set (step > 4) --> 1 possible solution path open
    if (selectedReadDirection == "L") {
      if (selectedFixDirection == "L") {
        validTargetActions.push(solutionPath1.find((step) => step.step === validActionsPerformed + 1).action);
      } else if (selectedFixDirection == "R") {
        validTargetActions.push(solutionPath2.find((step) => step.step === validActionsPerformed + 1).action);
      }
    } else if (selectedReadDirection == "R") {
      if (selectedFixDirection == "L") {
        validTargetActions.push(solutionPath3.find((step) => step.step === validActionsPerformed + 1).action);
      } else if (selectedFixDirection == "R") {
        validTargetActions.push(solutionPath4.find((step) => step.step === validActionsPerformed + 1).action);
      }
    }
  }
  return validTargetActions;
}

function setSolutionPath() {
  if (selectedReadDirection == "L") {
    if (selectedFixDirection == "L") {
      selectedSolutionPath = 1;
    }
    if (selectedFixDirection == "R") {
      selectedSolutionPath = 2;
    }
  }
  if (selectedReadDirection == "R") {
    if (selectedFixDirection == "L") {
      selectedSolutionPath = 3;
    }
    if (selectedFixDirection == "R") {
      selectedSolutionPath = 4;
    }
  }
}