<template>
  <ul class="cards-container">
    <li v-for="card in activeCardList" :key="card.id">
      <div
        class="card-frame"
        :class="{ cardFixed: card.fixActive }"
        @drop="dropHandler($event, card)"
        @dragover.prevent
        @dragenter.prevent
      >
        <div v-if="card.isDroppedTo" class="swap-confirmation-dialog">
          <div class="swap-confirmation-header">
            Karte {{ grabbedCard.id }}
            <br />
            <i class="bi bi-arrow-left-right"></i>
            <br />
            Karte {{ card.id }}
          </div>
          <div class="swap-confirmation-buttons">
            <div
              class="confirmation-btn-accept-small"
              @click="swapConfirmed(card)"
            >
              <i class="bi bi-check-lg"></i>
            </div>
            <div
              class="confirmation-btn-cancel-small"
              @click="card.isDroppedTo = false"
            >
              <i class="bi bi-x-lg"></i>
            </div>
          </div>
        </div>
        <div v-else class="card-content">
          <div
            class="fixieren-container unselectable"
            @click="fixCard(card.id)"
            :class="{ fixNotVisible: !card.fixVisible }"
          >
            FIX
          </div>

          <transition class="number-container" name="flip">
            <div
              class="card-number"
              :class="{ readCardNumber: card.cardFlipped }"
              :key="card.flipped"
              draggable="true"
              @dragstart="dragstartHandler($event, card)"
            >
              {{ !card.cardFlipped ? "" : card.number }}
            </div>
          </transition>

          <div class="btn-container">
            <div
              class="btn unselectable"
              :class="{
                readNotVisible: !card.lesenVisible,
              }"
              @click="readCard(card.id)"
            >
              <div v-if="!card.lesenActive">
                <i class="bi bi-eye" style="font-size: 1.6em"></i>
              </div>
              <div v-else>
                <i
                  class="bi bi-eye"
                  style="color: orange; font-size: 1.6em"
                ></i>
              </div>
            </div>
            <div
              class="btn unselectable"
              :class="{
                saveNotVisible: !card.merkenVisible,
              }"
              @click="saveCard(card.id)"
            >
              <div v-if="!card.merkenActive">
                <i class="bi bi-pin-angle" style="font-size: 1.6em"></i>
              </div>
              <div v-else>
                <i
                  class="bi bi-pin-angle-fill"
                  style="color: #1ea983; font-size: 1.6em"
                ></i>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="arrow-container">
        <ArrowIcon
          class="read-arrow"
          :color="card.lesenActive ? 'orange' : 'invisible'"
        />
        <ArrowIcon
          class="save-arrow"
          :color="card.merkenActive ? 'green' : 'invisible'"
        />
      </div>
    </li>
  </ul>
</template>

<script>
import ArrowIcon from "@/components/icons/ArrowIcon.vue";

export default {
  components: {
    ArrowIcon,
  },
  props: ["tutorialStep"],
  watch: {
    tutorialStep: function (newValue) {
      if (newValue == 1) {
        this.activeCardList = JSON.parse(JSON.stringify(this.cardListStep1));
      }
      if (newValue == 3) {
        this.activeCardList = JSON.parse(JSON.stringify(this.cardListStep3));
      }
      if (newValue == 5) {
        this.activeCardList = JSON.parse(JSON.stringify(this.cardListStep5));
      }
      if (newValue == 6) {
        this.activeCardList = JSON.parse(JSON.stringify(this.cardListStep6));
      }
      if (newValue == 8) {
        this.activeCardList = JSON.parse(JSON.stringify(this.cardListStep8));
      }
    },
  },
  emits: [
    "read-tutorial-finished",
    "swap-tutorial-finished",
    "save-tutorial-finished",
    "fix-tutorial-finished",
  ],
  data() {
    return {
      cardListStep1: [
        {
          id: 1,
          number: 5,
          lesenVisible: false,
          merkenVisible: false,
          fixVisible: false,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
        {
          id: 2,
          number: 6,
          lesenVisible: false,
          merkenVisible: false,
          fixVisible: false,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
      ],
      cardListStep3: [
        //LESEN
        {
          id: 1,
          number: 5,
          lesenVisible: true,
          merkenVisible: false,
          fixVisible: false,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
        {
          id: 2,
          number: 6,
          lesenVisible: true,
          merkenVisible: false,
          fixVisible: false,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
      ],
      cardListStep5: [
        //MERKEN
        {
          id: 1,
          number: 5,
          lesenVisible: true,
          merkenVisible: true,
          fixVisible: false,
          swapEnabled: false,
          lesenActive: true,
          merkenActive: false,
          fixActive: false,
          cardFlipped: true,
          isDroppedTo: false,
        },
        {
          id: 2,
          number: 6,
          lesenVisible: true,
          merkenVisible: true,
          fixVisible: false,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
      ],
      cardListStep6: [
        //TAUSCHEN
        {
          id: 1,
          number: 5,
          lesenVisible: true,
          merkenVisible: true,
          fixVisible: false,
          swapEnabled: true,
          lesenActive: true,
          merkenActive: true,
          fixActive: false,
          cardFlipped: true,
          isDroppedTo: false,
        },
        {
          id: 2,
          number: 6,
          lesenVisible: true,
          merkenVisible: true,
          fixVisible: false,
          swapEnabled: true,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
      ],
      cardListStep8: [
        //FIXIEREN
        {
          id: 1,
          number: 2,
          lesenVisible: false,
          merkenVisible: false,
          fixVisible: true,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
        {
          id: 2,
          number: 5,
          lesenVisible: false,
          merkenVisible: false,
          fixVisible: true,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
        {
          id: 3,
          number: 6,
          lesenVisible: false,
          merkenVisible: false,
          fixVisible: true,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
        {
          id: 4,
          number: 7,
          lesenVisible: false,
          merkenVisible: false,
          fixVisible: true,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
        {
          id: 5,
          number: 9,
          lesenVisible: false,
          merkenVisible: false,
          fixVisible: true,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
      ],
      activeCardList: [
        {
          id: 1,
          number: 5,
          lesenVisible: false,
          merkenVisible: false,
          fixVisible: false,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
        {
          id: 2,
          number: 6,
          lesenVisible: false,
          merkenVisible: false,
          fixVisible: false,
          swapEnabled: false,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          isDroppedTo: false,
        },
      ],
      grabbedCard: null,
      cardsRead: 0,
      cardsSaved: 0,
      cardsSwapped: 0,
      cardsFixed: 0,
      lastSwapConfirmed: false,
    };
  },
  methods: {
    // Start Drag&Drop functionality
    dragstartHandler(e, card) {
      if (this.isSwapEnabled(card.id)) {
        this.grabbedCard = card;
      }
    },
    dropHandler(e, card) {
      e.preventDefault();
      if(this.isSwapEnabled(card.id)){
        card.isDroppedTo = true;
      }
    },
    // End Drag&Drop functionality
    swapConfirmed(card) {
      if (!this.isCardFixed(card.id)) {
        let tmpNumber = card.number;
        card.number = this.grabbedCard.number;
        this.grabbedCard.number = tmpNumber;
        this.checkTutorialsFinished();
      }
      card.isDroppedTo = false;
    },
    // flip cards when read or saved
    flipCard(card) {
      if (card.lesenActive || card.merkenActive) {
        card.cardFlipped = true;
      } else if (!card.lesenActive && !card.merkenActive) {
        card.cardFlipped = false;
      }
    },

    fixCard(cardId) {
      if (this.tutorialStep >= 8) {
        if (!this.isCardFixed(cardId)) {
          const card = this.activeCardList.find((card) => card.id === cardId);
          card.fixActive = !card.fixActive;
          this.checkTutorialsFinished();
        }
      }
    },
    readCard(cardId) {
      if (!this.isCardFixed(cardId)) {
        this.unreadCards();
        const card = this.activeCardList.find((card) => card.id === cardId);
        card.lesenActive = !card.lesenActive;
        this.flipCard(card);
        this.checkTutorialsFinished();
      }
    },
    unreadCards() {
      this.activeCardList.forEach((card) => {
        if (card.lesenActive) {
          card.lesenActive = false;
          this.flipCard(card);
        }
      });
    },
    saveCard(cardId) {
      if (this.isCardRead(cardId) || this.isCardFixed(cardId)) {
        const card = this.activeCardList.find((card) => card.id === cardId);
        this.unsaveCards();
        card.merkenActive = !card.merkenActive;
        this.flipCard(card);
        this.checkTutorialsFinished();
      }
    },
    isCardRead(cardId) {
      return this.activeCardList.find((card) => card.id === cardId).lesenActive;
    },
    unsaveCards() {
      this.activeCardList.forEach((card) => {
        if (card.merkenActive) {
          card.merkenActive = false;
          this.flipCard(card);
        }
      });
    },
    isCardFixed(cardId) {
      return this.activeCardList.find((card) => card.id === cardId).fixActive;
    },
    isSwapEnabled(cardId) {
      return this.activeCardList.find((card) => card.id == cardId).swapEnabled;
    },

    // logic for tutorial

    //check if steps are performed correctly and show corresponding card

    checkTutorialsFinished() {
      switch (this.tutorialStep) {
        case 3:
          this.checkReadTutorialFinished();
          break;
        case 5:
          this.checkSaveTutorialFinished();
          break;
        case 6:
          this.checkSwapTutorialFinished();
          break;
        case 8:
          this.checkFixTutorialFinished();
          break;
      }
    },
    checkReadTutorialFinished() {
      this.cardsRead++;
      if (this.cardsRead >= 2) {
        this.$emit("read-tutorial-finished");
      }
    },
    checkSaveTutorialFinished() {
      this.cardsSaved++;
      if (
        this.activeCardList[0].cardFlipped &&
        this.activeCardList[1].cardFlipped
      ) {
        this.$emit("save-tutorial-finished");
      }
    },
    checkSwapTutorialFinished() {
      this.cardsSwapped++;
      if (this.cardsSwapped >= 1) {
        this.$emit("swap-tutorial-finished");
      }
    },
    checkFixTutorialFinished() {
      this.cardsFixed++;
      if (this.cardsFixed >= 2) {
        this.$emit("fix-tutorial-finished");
      }
    },
  },
};
</script>

<style scoped>
/** CARDS CONTAINER - ALL CARDS */
.cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
ul {
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
}
li {
  list-style-type: none;
  padding: 20px 20px;
  transition: all 0.3s ease;
}
.card-frame {
  border-radius: 10px;
  border: 2px solid black;
  height: 250px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
/** SWAP CONFIRMATION - FOR INDIVIDUAL CARD */
.swap-confirmation-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.swap-confirmation-header {
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 10px;
}
.swap-confirmation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
/** HIDE NOT VISIBLE PARTS IN TUTORIAL STEPS */
.fixNotVisible {
  visibility: hidden;
}
.readNotVisible {
  visibility: hidden;
}
.saveNotVisible {
  visibility: hidden;
}

/** CARD CONTENT*/
.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-number{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006bac;
  color: black;
  height: 100px;
  width: 120px;
  border: 2px solid black;
  border-radius: 10px;
  cursor: grab;
}
.card-number:hover {
  transform: scale(1.05);
}
.readCardNumber {
  background-color: white;
}
.fixieren-container {
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  padding: 4px 12px;
  margin: 10px;
}
.fixieren-container:hover {
  text-decoration: underline;
}
.cardFixed {
  background-color: #c2ebfa;
}
.btn-container {
  display: flex;
  justify-content: space-evenly;
  margin: 10px;
}
.btn {
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px;
}
.arrow-container {
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: space-around;
}
</style>
