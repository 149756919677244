<template>
  <div class="container">
    <div class="assignment-container">
      <div class="assignmentbar">
        <AssignmentbarSorting1
          :showHelpBtn="true"
          :showDismissBtn="true"
        ></AssignmentbarSorting1>
      </div>
      <ul class="cards-container">
        <li v-for="card in cardList" :key="card.id">
          <div :class="{ 'disabled': card.cardDisabled }">
            <div
              class="card-frame"
              :class="{ cardFixed: card.fixActive }"
              @drop="dropHandler($event, card)"
              @dragover.prevent
              @dragenter.prevent
            >
              <div v-if="card.isDroppedTo" class="swap-confirmation-dialog">
                <div class="swap-confirmation-header">
                  Karte {{ grabbedCard.id }}
                  <br />
                  <i class="bi bi-arrow-left-right"></i>
                  <br />
                  Karte {{ card.id }}
                </div>
                <div class="swap-confirmation-buttons">
                  <div
                    class="confirmation-btn-accept-small"
                    @click="swapConfirmed(card)"
                  >
                    <i class="bi bi-check-lg"></i>
                  </div>
                  <div
                    class="confirmation-btn-cancel-small"
                    @click="card.isDroppedTo = false"
                  >
                    <i class="bi bi-x-lg"></i>
                  </div>
                </div>
              </div>
              <div v-else class="card-content">
                <div
                  class="fixieren-container unselectable"
                  @click="fixCard(card)"
                >
                  FIX
                </div>

                <transition class="number-container" name="flip">
                  <div
                    class="card-number"
                    :class="{ readCardNumber: card.cardFlipped }"
                    :key="card.flipped"
                    draggable="true"
                    @dragstart="dragstartHandler($event, card)"
                    @touchstart="dragstartHandler($event, card)"
                  >
                    {{ !card.cardFlipped ? "" : card.number }}
                  </div>
                </transition>

                <div class="btn-container">
                  <div class="btn unselectable" @click="readCard(card)">
                    <div v-if="!card.lesenActive">
                      <i class="bi bi-eye" style="font-size: 1.6em"></i>
                    </div>
                    <div v-else>
                      <i
                        class="bi bi-eye"
                        style="color: orange; font-size: 1.6em"
                      ></i>
                    </div>
                  </div>
                  <div class="btn unselectable" @click="saveCard(card)">
                    <div v-if="!card.merkenActive">
                      <i class="bi bi-pin-angle" style="font-size: 1.6em"></i>
                    </div>
                    <div v-else>
                      <i
                        class="bi bi-pin-angle-fill"
                        style="color: #1ea983; font-size: 1.6em"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="arrow-container">
            <ArrowIcon
              class="read-arrow"
              :color="card.lesenActive ? 'orange' : 'invisible'"
            />
            <ArrowIcon
              class="save-arrow"
              :color="card.merkenActive ? 'green' : 'invisible'"
            />
          </div>
        </li>
      </ul>

      <div class="feedback-container">
        <Assignment1FeedbackField
          :feedbackMessage="feedbackMessage"
          :lastActionValid="lastActionValid"
          @undo-clicked="undoLastAction"
        >
        </Assignment1FeedbackField>
      </div>
      <div
          v-if="isSortingFinished()"
          type="button"
          @click="$emit('assignment1-finished')"
          class="custom-btn"
          style="float:right"
        >
          Weiter
        </div>
    </div>
  </div>
</template>

<script>
import * as processing from "@/scripts/sorting/a1/a1-processing.js";


import AssignmentbarSorting1 from "@/components/inf/sorting/Assignment1/AssignmentbarSorting1.vue";

import Assignment1FeedbackField from "@/components/inf/sorting/Assignment1/Assignment1FeedbackField.vue";
import ArrowIcon from "@/components/icons/ArrowIcon.vue";

export default {
  components: {
    AssignmentbarSorting1,
    Assignment1FeedbackField,
    ArrowIcon,
  },
  emits: ["assignment1-finished"],
  data() {
    return {
      cardList: [
        {
          id: 1,
          number: 5,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          cardDisabled: false,
          isDroppedTo: false,
        },
        {
          id: 2,
          number: 4,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          cardDisabled: false,
          isDroppedTo: false,
        },
        {
          id: 3,
          number: 2,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          cardDisabled: false,
          isDroppedTo: false,
        },
        {
          id: 4,
          number: 1,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          cardDisabled: false,
          isDroppedTo: false,
        },
        {
          id: 5,
          number: 3,
          lesenActive: false,
          merkenActive: false,
          fixActive: false,
          cardFlipped: false,
          cardDisabled: false,
          isDroppedTo: false,
        },
      ],
      grabbedCard: null,
      feedbackMessage: "",
      lastActionValid: true,
      lastValidCardList: null,
      workspaceLocked: false,
      cardDragEnabled: true,
      cardDisabled: false,
    };
  },
  mounted() {
    let rand = Math.floor(Math.random() * 9) + 1;
    this.cardList.forEach((card) => {
      card.number = card.number * rand;
    });
    this.setLastValidCardList();
    processing.resetSelectionSort();
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  methods: {
    //** drag&drop functionality **/
    dragstartHandler(e, card) {
      if (!this.workspaceLocked) {
        this.grabbedCard = card;
      }
    },
    dropHandler(e, card) {
      if (!this.workspaceLocked) {
        e.preventDefault();
        card.isDroppedTo = true; //set is dropped to true to show swap confirmation dialog
      }
    },
    swapConfirmed(card) {
      //set smaller and bigger card id for comparison with reference solutions in a1-processing.js
      let smallerId = Math.min(card.id, this.grabbedCard.id);
      let biggerId = Math.max(card.id, this.grabbedCard.id);
      this.performAction("T", smallerId, biggerId); //sets lastActionValid variable
      if (this.lastActionValid) {
        // swap cards
        let tmpNumber = card.number;
        card.number = this.grabbedCard.number;
        this.grabbedCard.number = tmpNumber;
        this.unreadAllCards();
        this.unsaveAllCards();
        this.setLastValidCardList();
      }
      card.isDroppedTo = false;
    },
    //** read, save, fix (lesen, merken, fixieren) **/
    readCard(card) {
      if (!this.workspaceLocked) {
        this.performAction("L", card.id, null);
        this.unreadAllCards();
        //const card = this.getCard(cardId);
        card.lesenActive = !card.lesenActive; // lesenActive umkehren
        if (this.lastActionValid) {
          this.flipCard(card); // karte umdrehen
          this.setLastValidCardList();
        }
      }
    },
    unreadAllCards() {
      if (!this.workspaceLocked) {
        this.cardList.forEach((card) => {
          if (card.lesenActive) {
            card.lesenActive = false;
            this.flipCard(card);
          }
        });
      }
    },
    saveCard(card) {
      if (!this.workspaceLocked) {
        this.performAction("M", card.id, null);
        this.unsaveAllCards();
        //const card = this.getCard(cardId);
        card.merkenActive = !card.merkenActive;
        if (this.lastActionValid) {
          this.flipCard(card);
          this.setLastValidCardList();
        }
      }
    },
    unsaveAllCards() {
      if (!this.workspaceLocked) {
        this.cardList.forEach((card) => {
          if (card.merkenActive) {
            card.merkenActive = false;
            this.flipCard(card);
          }
        });
      }
    },
    fixCard(card) {
      if (!this.workspaceLocked) {
        //const card = this.getCard(cardId);
        card.fixActive = !card.fixActive;
        this.performAction("F", card.id, null);
        if (this.lastActionValid) {
          this.setLastValidCardList();
        }
      }
    },

    //** helper methods **/
    getCard(cardId) {
      return this.cardList.find((card) => card.id === cardId);
    },
    setLastValidCardList() {
      this.lastValidCardList = this.cardList.map((el) => {
        return { 
          ...el,
          isDroppedTo: false //set isDroppedTo to false -> do not show dropDialog again is last valid 
         };
      });
    },
    isCardDisabled(card) {
      if (!this.lastActionValid) {
        //last action was error --> highlight last cards by disabling unused cards
        card;
      }
    },
    flipCard(card) {
      if (!this.workspaceLocked) {
        if (card.lesenActive || card.merkenActive) {
          card.cardFlipped = true;
        } else if (!card.lesenActive && !card.merkenActive) {
          card.cardFlipped = false;
        }
      }
    },
    undoLastAction() {
      this.cardList = this.lastValidCardList.map((el) => {
        return { ...el };
      });
      this.workspaceLocked = false;
      this.feedbackMessage = "";
      this.lastActionValid = true;
      this.toggleCardDraggable();
      this.unHighlightError();
    },
    isSortingFinished() {
      return processing.isSortingFinished();
    },
    //** logging **/
    performAction(tool, card1, card2) {
      if (!this.workspaceLocked) {
        let action = { tool: tool, card1: card1, card2: card2 }; //create current action json

        if (processing.actionPerformed(action, this.cardList)) {
          //valid action
          this.lastActionValid = true;
        } else {
          //invalid action
          this.workspaceLocked = true;
          this.lastActionValid = false;
          this.toggleCardDraggable();
          this.highlightError(card1, card2);
        }
        //set feedbackMessage
        this.feedbackMessage = processing.getFeedbackMessage();
      }
    },
    toggleCardDraggable() {
      var elms = document.getElementsByClassName("number");
      for (var i = 0; i < elms.length; i++) {
        elms[i].setAttribute("draggable", !this.workspaceLocked);
      }
    },
    highlightError(card1, card2) {
      //disable all cards
      this.cardList.forEach((card) => {
        card.cardDisabled = true;
      });
      //enable false cards
      if (card1 != null) {
        this.getCard(card1).cardDisabled = false;
      }
      if (card2 != null) {
        this.getCard(card2).cardDisabled = false;
      }
    },
    unHighlightError() {
      this.cardList.forEach((card) => {
        card.cardDisabled = false;
      });
    },
  },
};
</script>

<style scoped>
/** CARDS CONTAINER - ALL CARDS */
.cards-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}
ul {
  padding-left: 0;
  display: flex;
  flex-flow: row wrap;
}

li {
  list-style-type: none;
  padding: 20px 20px;
  transition: all 0.3s ease;
}
.card-frame {
  border-radius: 10px;
  border: 2px solid black;
  height: 250px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
/** SWAP CONFIRMATION - FOR INDIVIDUAL CARD */
.swap-confirmation-dialog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.swap-confirmation-header {
  text-align: center;
  font-size: 1.8em;
  margin-bottom: 10px;
}
.swap-confirmation-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}
/** CARD CONTENT*/
.card-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.card-number{
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #006bac;
  color: black;
  height: 100px;
  width: 120px;
  border: 2px solid black;
  border-radius: 10px;
  cursor: grab;
}
.card-number:hover {
  transform: scale(1.05);
}
.readCardNumber {
  background-color: white;
}
.fixieren-container {
  cursor: pointer;
  border: 1px solid black;
  border-radius: 10px;
  padding: 4px 12px;
  margin: 10px;
}
.fixieren-container:hover {
  text-decoration: underline;
}
.cardFixed {
  background-color: #c2ebfa;
}
.btn-container {
  display: flex;
  justify-content: space-evenly;
  margin: 10px;
}
.btn {
  background-color: white;
  border: 1px solid black;
  border-radius: 10px;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px;
}
.arrow-container {
  width: 150px;
  height: 50px;
  display: flex;
  justify-content: space-around;
}

.disabled {
  pointer-events: none;
  opacity: 0.4;
}

/** TRANSITION - NOT WORKING
.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: scaleY(2);
  opacity: 0;
}*/
</style>
