<template>
  <div class="container mb-2 pb-2">
    <div class="button-bar assignment-bar">
      <!-- Button trigger modal -->
      <div class="button-bar-left">
        <div v-if="showHelpBtn" type="button" @click="openHelpModal" class="custom-btn-secondary dismiss-btn"
          data-bs-toggle="modal" data-bs-target="#modal">
          <i class="bi bi-question-circle" style="padding-right: 5px;"></i>Hilfe
        </div>
      </div>
      <div class="button-bar-right">
        <div v-if="showDismissBtn" type="button" @click="openQuitModal" class="custom-btn-secondary dismiss-btn"
          data-bs-toggle="modal" data-bs-target="#modal">
          „Sortieren mit System“ abbrechen
        </div>
        <div v-if="showFinishBtn" type="button" @click="openFinishModal" class="custom-btn dismiss-btn"
          data-bs-toggle="modal" data-bs-target="#modal">
          Weiter
        </div>
      </div>
    </div>

    <BaseModal>
      <template v-slot:header>
        <div class="modal-title">
          <!-- help modal title -->
          <div v-if="showModal === 'help'">Hilfe</div>
          <!-- quit modal title -->
          <div v-else-if="showModal === 'quit'">„Sortieren mit System“ abbrechen?</div>
          <!-- finish modal title -->
          <div v-else-if="showModal === 'finish'">Aufgabe abgeben</div>
          <div v-else>Error</div>
        </div>
      </template>

      <template v-slot:body>
        <!-- help modal body -->
        <div v-if="showModal === 'help'">
          <p>Versuchen Sie, die Anweisungen in der richtigen Reihenfolge zu einen Algorithmus zusammenzustellen! Dieser soll die Kärtchen von links nach rechts aufsteigend sortieren. Ziehen
            Sie dafür die Skripte auf die entsprechenden Ablageflächen.
          </p>
          <p>
            Der Computer/das Programm durchläuft nach dem Start die Skripte des Algorithmus von oben nach unten. Sobald
            eine Anweisung/Zeile ausgeführt wurde, wird mit der nächsten Zeile
            fortgesetzt.</p>
          <p>Sie haben jederzeit die Möglichkeit zu testen, welche Handlungsschritte Ihr Algorithmus vollzieht.
            Dafür stehen Ihnen diese Schaltflächen zur Verfügung:
          </p>
          <div id="algorithm-button-container">
            <div><button class="button" title="Algorithmus starten">
                <i class="bi bi-play-circle"></i>
              </button><button class="button" title="Algorithmus pausieren">
                <i class="bi bi-pause-circle"></i>
              </button>Algorithmus starten bzw. pausieren</div>
            <div><button class="button" title="Algorithmus stoppen">
                <i class="bi bi-stop-circle"> </i>
              </button>Algorithmus stoppen</div>
            <div><button class="button" title="Schritt vorwärts">
                <i class="bi bi-arrow-down-circle"> </i>
              </button>Schritt vorwärts</div>
          </div>
        </div>
        <!-- quit modal body -->
        <div v-else-if="showModal === 'quit'">
          <p>
            Wollen Sie das Modul „Sortieren mit System“ wirklich abbrechen? Bitte beachten Sie, dass Ihre bisherigen Fortschritte nicht gespeichert werden und 
            Sie keine Rückmeldung zu Ihrer aktuellen Bearbeitung der Aufgaben bekommen, wenn Sie jetzt abbrechen.
          </p>
          <p>
            Falls Sie dennoch abbrechen möchten, teilen Sie uns bitte Ihren
            Grund mit:
          </p>
          <form>
            <div class="form-group">
              <textarea class="form-control" id="message-text"></textarea>
            </div>
          </form>
        </div>
        <!-- finish modal body -->
        <div v-else-if="showModal === 'finish'">
          <div v-if="sortingFinished">
            <p>
              Sehr gut! Sie haben alle Zahlen nun in aufsteigender Reihenfolge
              sortiert!
            </p>
            <p>
              Zum Abschluss bitten wir Sie, einige Fragen zum Experiment zu
              beantworten.
            </p>
          </div>
          <div v-else>
            <p>
              Sie haben die Aufgabe noch nicht bis zur Lösung bearbeitet. Wollen
              Sie an dieser Stelle abbrechen?
            </p>
            <p>
              Bitte beachten Sie, dass Sie keine Rückmeldung zum OSA Teil
              „Sortieren mit System!“ bekommen werden, wenn Sie die Bearbeitung
              der Aufgabe jetzt abbrechen.
            </p>
            <p>
              Falls Sie dennoch abbrechen möchten, teilen Sie uns bitte Ihren
              Grund mit:
            </p>

            <form>
              <div class="form-group">
                <textarea class="form-control" id="message-text"></textarea>
              </div>
            </form>
          </div>
        </div>
        <div v-else>Error loading text</div>
      </template>

      <template v-slot:footer>
        <div v-if="showModal === 'help'">
          <button type="button" class="btn custom-btn" data-bs-dismiss="modal">
            OK
          </button>
        </div>
        <!-- quit modal -->
        <div v-else-if="showModal === 'quit'">
          <button type="button" class="btn custom-btn-secondary" data-bs-dismiss="modal">
            Nein, Modul fortsetzen
          </button>
          <button type="button" class="btn custom-btn-danger" data-bs-dismiss="modal" @click="endAssignment">
            Ja, Modul abbrechen
          </button>
        </div>
        <!-- finish modal -->
        <div v-else-if="showModal === 'finish'">
          <div v-if="sortingFinished">
            <button type="button" class="btn custom-btn" data-bs-dismiss="modal"
              @click="$emit('finish-continue-clicked')">
              Weiter
            </button>
          </div>
          <div v-else>
            <button type="button" class="btn custom-btn-danger" data-bs-dismiss="modal" @click="endAssignment">
              Aufgabe abbrechen
            </button>
            <button type="button" class="btn custom-btn" data-bs-dismiss="modal">
              Aufgabe fortsetzen
            </button>
          </div>
        </div>
        <div v-else>
          <button type="button" class="btn custom-btn" data-bs-dismiss="modal">
            Schließen
          </button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";

export default {
  name: "AssignmentbarSorting2",
  components: {
    BaseModal,
  },
  data() {
    return {
      showModal: "",
    };
  },
  props: {
    showHelpBtn: Boolean,
    showDismissBtn: Boolean,
    showFinishBtn: Boolean,
    sortingFinished: Boolean,
  },
  emits: ["end-assignment", "skip-tutorial", "finish-assignment"],
  methods: {
    openHelpModal() {
      this.showModal = "help";
    },
    openQuitModal() {
      this.showModal = "quit";
    },
    openFinishModal() {
      this.showModal = "finish";
    },
    endAssignment() {
      let messageText = document.getElementById("message-text").value;
      if (messageText.length > 1) {
        this.saveQuitMessage(messageText);
      }

      this.$emit("end-assignment");
      this.$router.push("/informatik");
    },
    async saveQuitMessage(messageText) {
      try {
        await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
          "/api/inf-sorting-quit-messages",
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
            body: JSON.stringify({
              data: {
                message: messageText,
              },
            }),
          }
        );
      } catch (error) {
        console.log(error);
      }
    },
    finishSorting() {
      this.$emit("finish-assignment");
    },
  },
};
</script>

<style scoped>
#algorithm-button-container {
  margin-left: 10px;
}

.button {
  background-color: #006bac;
  border-radius: 5px;
  color: white;
  font-size: 1.8em;
  text-align: center;
  text-decoration: none;
  padding: 0.2em;
  margin: 0.1em 0.5em 0.1em 0.1em;
}
</style>
