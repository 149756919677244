<template>
    <div class="selection-vis-container">
        <div id="buttons">
            <button @click="selectionSort">Selection Sort</button>
            <button @click="stop = true" style="margin-left:40px">Stop</button>
            <button @click="reset">Reset</button>
        </div>

        <div id="selection-vis"></div>

        <div>Steps: <span id="selection-counter">0</span></div>
    </div>
</template>

<script>
import * as d3 from 'd3';

export default {
    data() {
        return {
            durationTime: 0,

            array: [],
            unsortedArray: [],
            sortedArray: [],
            count: 0,

            stop: false,
            steps: 0,

            margin: { top: 40, right: 0, bottom: 0, left: 0 },
            width: 0,
            height: 0,
            barWidth: 0,

            x: null,
            svg: null,
            rects: null,
            labels: null,
        }
    },


    props: ['n', 'stepDuration'],

    mounted() {
        this.count = Number(this.n) + 1;
        this.array = d3.shuffle(d3.range(1, this.count))
        this.durationTime = Number(this.stepDuration);
        this.unsortedArray = [...this.array];
        this.sortedArray = [];


        this.width = 700 - this.margin.left - this.margin.right;
        this.height = 300 - this.margin.top - this.margin.bottom;
        this.barWidth = this.width / this.count;

        this.x = d3.scaleLinear()
            .domain([0, this.count])
            .range([0, this.width]);

        this.svg = d3.select("#selection-vis").append("svg")
            .attr("width", this.width + this.margin.left + this.margin.right)
            .attr("height", this.height + this.margin.top + this.margin.bottom)
            .append("g")
            .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

        this.rects = this.svg.append("g")
            .attr("transform", "translate(" + this.barWidth + ",2)")
            .selectAll("rect")
            .data(this.unsortedArray)
            .enter().append("rect");

        this.labels = this.svg.selectAll("text")
            .data(this.unsortedArray)
            .enter().append("text");


        this.labels.attr("id", function (d) { return "selection-text" + d })
            .attr('x', (d, i) => { return this.x(i) })
            .attr('y', () => { return 0 })
            .html(function (d) { return d; });

        this.rects.attr("id", function (d) { return "selection-rect" + d })
            .attr("width", this.barWidth * .9)
            .attr("height", (d) => { return (d * this.barWidth / 3) })
            .attr('x', (d, i) => { return this.x(i) - this.barWidth })
            .attr('y', (d) => { return this.height - (d * this.barWidth / 3) })
    },


    methods: {
        async reset() {
            this.stop = true;

            await d3.timeout(() => {
                // Reset arrays
                this.unsortedArray = [...this.array];
                this.sortedArray = [];
                this.stop = false;

                d3.select("#selection-counter").html(this.steps = 0) // Reset step counter

                this.labels.attr("class", "")
                    .classed("testing", false)
                    .classed("sorted", false)
                    .transition().duration(2000)
                    .attr("x", (d, i) => { return this.x(i); })

                this.rects.attr("class", "")
                    .transition().duration(2000)
                    .attr("x", (d, i) => { return this.x(i - 1); })

            }, this.durationTime * 2);


        },

        selectionSort() {
            let min = this.count;
            let spliceIndex;
            let i = 0;

            function findMin(that) {
                if (that.stop) return that.stop = false;

                d3.timeout(function () {
                    if (i <= that.unsortedArray.length) {
                        d3.select("#selection-rect" + that.unsortedArray[i]).attr("class", "")
                        d3.select("#selection-rect" + that.unsortedArray[i]).attr("class", "testing")
                        d3.select("#selection-counter").html(++that.steps);

                        d3.timeout(function () {
                            if (that.unsortedArray[i] < min) {
                                d3.select("#selection-rect" + that.unsortedArray[i]).attr("class", "min")
                                d3.select("#selection-rect" + min).attr("class", "")
                                min = that.unsortedArray[spliceIndex = i]
                            }
                            else {
                                d3.select("#selection-rect" + that.unsortedArray[i]).attr("class", "")
                            }
                            i++;

                            return findMin(that);
                        }, that.durationTime / 2);

                    } else {
                        that.sortedArray.push(min);

                        that.unsortedArray.splice(spliceIndex, 1); //delete 1 element at spliceIndex
                        that.unsortedArray.splice(spliceIndex, 0, that.unsortedArray[0]); //insert first element at spliceIndex
                        that.unsortedArray.splice(0, 1); //delete first element


                        that.rects.attr("class", "")
                        that.rects.classed("sorted", function (d) { return that.sortedArray.indexOf(d) == d - 1; })
                        that.labels.classed("sorted", function (d) { return that.sortedArray.indexOf(d) == d - 1; })

                        d3.select("#selection-counter").html(++that.steps);

                        that.slide(min, that.sortedArray.length - 1);
                        that.slide(that.unsortedArray[spliceIndex - 1], that.sortedArray.length + spliceIndex - 1);

                        d3.timeout(function () {
                            if (that.unsortedArray.length > 0) that.selectionSort();
                        }, that.durationTime);
                        return;
                    }
                })
            }

            findMin(this);
        },



        // Slides the text and rect of d to the position of index i 
        slide(d, i) {
            d3.select("#selection-text" + d)
                .transition().duration(this.durationTime)
                .attr("x", () => { return this.x(i); })

            d3.select("#selection-rect" + d)
                .transition().duration(this.durationTime)
                .attr("x", () => { return this.x(i - 1); })
        }

    }

}
</script>


<style scoped>
#buttons>button {
    background-color: darkgreen;
    border-radius: 5px;
    color: white;
    margin-right: 10px;
}

.selection-vis-container {
    margin: 20px;
    margin-left: 20px;
}

#selection-vis {
    margin: 10px 0;
}

#selection-vis :deep(text) {
    fill: black;
}

#selection-vis :deep(rect) {
    fill: darkgreen;
}

#selection-vis :deep(.sorted) {
    fill: #999;
}

#selection-vis :deep(.min) {
    fill: red;
}

#selection-vis :deep(.testing) {
    fill: orange;
}
</style>