export {performAction, resetSelectionSort, getFeedbackMessage, isSortingFinished, getReadDirection, getFixDirection, }

//Rückmeldung
// 0 = ""
// 1 = "Sehr gut, Sie haben nun die erste Karte gelesen! Machen Sie weiter!"
// 2 = "Gut gemacht! Der Merkzeiger hält die Karte nun sichtbar und ermöglicht Ihnen weiterzuarbeiten!"
// 3 = "Durch das von Ihnen gewählte systematische Vorgehen, konnten Sie nun alle Karten erfolgreich lesen. Wie geht es nun weiter?"
// 4 = "Der erste Kartentausch hat gut geklappt. Was ist jetzt noch zu tun um die restlichen Karten zu sortieren."
// 5 = "Sie haben erfolgreich die erste Karte fixiert und damit einer Karte die endgültige Position zugewiesen. Das weitere Sortieren sollte Ihnen jetzt keine großen Schwierigkeiten mehr bereiten."
// 6 = "Auch dieser Tausch ist Ihnen geglückt! Sie werden sehen, je weniger verbleibende Karten es gibt, desto schneller werden Sie vorankommen."
// 7 = "Nur noch zwei verbleibende Karten sind zu sortieren. Bald haben Sie es geschafft!"
// 8 = "Sehr gut! Die vorletzte Karte ist fixiert. Was ist jetzt noch zu tun?"
// 9 = "Glückwunsch! Sie haben erfolgreich die Sortieraufgabe gelöst! Dabei haben Sie die Karten nach den Regeln eines Computerprogrammes sortiert. In weiterer Folge wird es darum gehen, dieses Vorgehen in eine Handlungsanweisung (Algorithmus) für einen Computer zu beschreiben."
// 10 = "Sie haben die bisherigen Schritte gut gelöst.Von nun an erscheinen in dieser Box nur mehr Meldungen, welche sie für die weitere Bearbeitung erforderlich sind."

const correctResponses = [
  "",
  "Sehr gut, Sie haben nun die erste Karte gelesen! Machen Sie weiter!",
  "Gut gemacht! Der Merkzeiger hält die Karte nun sichtbar und ermöglicht Ihnen weiterzuarbeiten!",
  "Durch das von Ihnen gewählte systematische Vorgehen, konnten Sie nun alle Karten erfolgreich lesen. Wie geht es nun weiter?",
  "Der erste Kartentausch hat gut geklappt. Was ist jetzt noch zu tun um die restlichen Karten zu sortieren.",
  "Sie haben erfolgreich die erste Karte fixiert und damit einer Karte die endgültige Position zugewiesen. Das weitere Sortieren sollte Ihnen jetzt keine großen Schwierigkeiten mehr bereiten.",
  "Auch dieser Tausch ist Ihnen geglückt! Sie werden sehen, je weniger verbleibende Karten es gibt, desto schneller werden Sie vorankommen.",
  "Nur noch zwei verbleibende Karten sind zu sortieren. Bald haben Sie es geschafft!",
  "Sehr gut! Die vorletzte Karte ist fixiert. Was ist jetzt noch zu tun?",
  "Glückwunsch! Sie haben erfolgreich die den ersten Teil der Kartensortieraufgabe gelöst! Dabei haben Sie die Karten nach den Regeln eines Computerprogrammes sortiert. Im nächsten Teil wird es darum gehen, dieses Vorgehen in eine Handlungsanweisung (Algorithmus) für einen Computer zu beschreiben.",
  "Sie haben die bisherigen Schritte gut gelöst. Von nun an erscheinen in dieser Box nur mehr Meldungen, welche sie für die weitere Bearbeitung erforderlich sind."
];

// IMPORT REFERENCE SOLUTION PATHS
const solutionPath1 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath1.json");
const solutionPath2 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath2.json");
const solutionPath3 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath3.json");
const solutionPath4 = require("@/scripts/sorting/a1/reference_solution_steps/solutionPath4.json");

var sortingFinished = false;
var feedbackMessageIndex = 0; 
var validActionsPerformed = 0;

function performAction(selectedSolutionPath){
  validActionsPerformed++;
  setValidActionFeedbackMessage(selectedSolutionPath);
}

function resetSelectionSort(){
  sortingFinished = false;
  feedbackMessageIndex = 0;
  validActionsPerformed = 0;
}

/**
 * Returns the string from correctResponses matching with the current step of the solution.
 * @param {int} solutionPath selectedSolutionPath
 * @returns {String} response of current step in selected solution path
 */
function getFeedbackMessage() {
  return correctResponses[feedbackMessageIndex];
}

function isSortingFinished(){
  return sortingFinished;
}

/**
 * Checks the action performed by the user and sets the reading direction.
 * If a the performed action is valid the reading direction is set to left reading or right reading.
 * @param {Object} performedAction action performed by the user
 * @returns true if performedAction is valid or false if performedAction is invalid
 */
function getReadDirection(performedAction) {
  if (performedAction.card1 == 1) {
    return "L";
  } else if (performedAction.card1 == 5) {
    return "R";
  } else {
    throw new Error("ERROR: No valid action performed. Cannot set Reading Direction!");
  }
}

/**
 * Checks the action performed by the user and compares it with the solution paths to determine the fix direction.
 * If a the performed action is valid and validActionsPerformed is >= 4 (step that determines the fixing direction) the fix direction is set to left fixing or right fixing.
 * @param {Object} performedAction action performed by the user
 * @returns true if performedAction is valid or false if performedAction is invalid
 */
function getFixDirection(performedAction, selectedReadDirection) {
  if (selectedReadDirection == "L") {
    if (performedAction.card1 == 2) {
      return "L";
    } else if (performedAction.card1 == 3) {
      return "R";
    } else {
      throw new Error("ERROR: No valid action performed. Cannot set Fix Direction!");
    }
  }
  if (selectedReadDirection == "R") {
    if (performedAction.card1 == 4) {
      return "L";
    } else if (performedAction.card1 == 3) {
      return "R";
    } else {
      throw new Error("ERROR: No valid action performed. Cannot set Fix Direction!");
    }
  } else {
    throw new Error("ERROR: No valid action performed. Cannot set Fix Direction!");
  }
}


/**
 * Sets the feedbackMessage string for valid actions
 */
 function setValidActionFeedbackMessage(selectedSolutionPath) {
  switch (selectedSolutionPath) {
    case 0:
      //no solution path selected yet --> get response from first solution path
      feedbackMessageIndex = solutionPath1.find((solStep) => solStep.step === validActionsPerformed).response;
      return;
    case 1:
      feedbackMessageIndex = solutionPath1.find((solStep) => solStep.step === validActionsPerformed).response;
      if (validActionsPerformed >= solutionPath1.length) {
        sortingFinished = true;
      }
      return;
    case 2:
      feedbackMessageIndex = solutionPath2.find((solStep) => solStep.step === validActionsPerformed).response;
      if (validActionsPerformed >= solutionPath2.length) {
        sortingFinished = true;
      }
      return;
    case 3:
      feedbackMessageIndex = solutionPath3.find((solStep) => solStep.step === validActionsPerformed).response;
      if (validActionsPerformed >= solutionPath3.length) {
        sortingFinished = true;
      }
      return;
    case 4:
      feedbackMessageIndex = solutionPath4.find((solStep) => solStep.step === validActionsPerformed).response;
      if (validActionsPerformed >= solutionPath4.length) {
        sortingFinished = true;
      }
      return;
    default:
      throw new Error("ERROR: No valid solution path detected");
  }
}
