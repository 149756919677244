<template>
  <!-- 
  MAIN COMPONENT FOR SELECTION SORT ASSIGNMENT 
  on start it shows the tutorial
  after the tutorial is finished or skipped it shows the assignment
-->

  <div class="container" v-if="this.dataReady">
    
    <div style="color: red; position: absolute; top: 0; left: 0; display: flex; flex-direction: column;" v-if="false">
      BUTTONS ARE FOR DEVELOPMENT <br />
      <button @click="startIntroduction">Intro</button>
      <button @click="startIntroduction2">Intro2</button>
      <button @click="startAssignment1Tutorial">Assign1 Tutorial</button>
      <button @click="startAssignment1">Assign1</button>
      <button @click="startAssignment2Tutorial">Assign2 Tutorial</button>
      <button @click="startAssignment2">Assign2</button>
      <button @click="startSortingConclusion">Conclusion</button>
    </div>

    <div class="error-message" v-if="!this.user">
      Um auf das Modul "Sortieren mit System" zugreifen zu können, müssen Sie angemeldet sein.
      <router-link to="/"><div class="custom-btn">Zur Anmeldung</div></router-link>
    </div>
    <div class="error-message" v-else-if="this.isSortingCompleted">
      Sie haben das Modul "Sortieren mit System" bereits abgeschlossen. Eine erneute Bearbeitung ist derzeit nicht möglich.
      <router-link to="/informatik"><div class="custom-btn">Zum Informatik OSA</div></router-link>
    </div>
    <div v-else>
      <div class="introduction-container" v-if="showIntroduction">
        <SortingIntro
          @sorting-intro-finished="startIntroduction2"
          @start-sorting-intro2-shortcut="startIntroduction2"
        ></SortingIntro>
      </div>
      <div class="row" v-if="showIntroduction2">
        <SortingIntro2
          @sorting-intro2-back="startIntroduction"
          @sorting-intro2-finished="startAssignment1Tutorial"
        ></SortingIntro2>
      </div>
      <div class="row" v-if="showAssignment1Tutorial">
        <Assignment1Tutorial
          @assignment1-tutorial-back="startIntroduction2"
          @assignment1-tutorial-finished="startAssignment1"
        ></Assignment1Tutorial>
      </div>
      <div class="row" v-if="showAssignment1">
        <Assignment1
          @assignment1-finished="startAssignment2Tutorial"
        ></Assignment1>
      </div>
      <div class="row" v-if="showAssignment2Tutorial">
        <Assignment2Tutorial
          @assignment2-tutorial-finished="startAssignment2"
        ></Assignment2Tutorial>
      </div>
      <div class="row" v-if="showAssignment2">
        <Assignment2
          @assignment2-finished="startSortingConclusion"
        ></Assignment2>
      </div>
      <div class="row" v-if="showSortingConclusion">
        <SortingConclusion
          @sorting-conclusion-finished="finishSorting"
        ></SortingConclusion>
      </div>
    </div>
  </div>
</template>

<script>
import SortingIntro from "@/components/inf/sorting/SortingIntro.vue";
import SortingIntro2 from "@/components/inf/sorting/SortingIntro2.vue";
import Assignment1Tutorial from "@/components/inf/sorting/Assignment1/Assignment1Tutorial.vue";
import Assignment1 from "@/components/inf/sorting/Assignment1/Assignment1.vue";
import Assignment2Tutorial from "@/components/inf/sorting/Assignment2/Assignment2Tutorial.vue";
import Assignment2 from "@/components/inf/sorting/Assignment2/Assignment2.vue";
import SortingConclusion from "@/components/inf/sorting/SortingConclusion.vue";

import * as myOSAhandler from "@/scripts/inf_my-osa-api-handler.js";

export default {
  components: {
    SortingIntro,
    SortingIntro2,
    Assignment1Tutorial,
    Assignment1,
    Assignment2Tutorial,
    Assignment2,
    SortingConclusion,
    
  },
  data() {
    return {
      dataReady: false,
      user: {},
      isSortingCompleted: false,
      showIntroduction: true,
      showIntroduction2: false,
      showAssignment1Tutorial: false,
      showAssignment1: false,
      showAssignment2Tutorial: false,
      showAssignment2: false,
      showSortingConclusion: false,
    };
  },
  //async 
  mounted() {
    this.user = JSON.parse(window.localStorage.getItem("userData"));
    //this.isSortingCompleted = await this.getIsSortingCompleted();
    this.$store.dispatch('setHeaderPageTitle', 'Online Self Assessment Informatik');
    this.$store.dispatch('setIsUserInAssignment', false); //user opened sortingView --> user is in assignment
    this.dataReady = true;
  },
  unmounted: function () {
    this.$store.dispatch("setIsUserInAssignment", false); //user closed sortingView --> user is not in assignment anymore
  },
  methods: {
    hideAllComponents() {
      this.showIntroduction = false;
      this.showIntroduction2 = false;
      this.showAssignment1Tutorial = false;
      this.showAssignment1 = false;
      this.showAssignment2Tutorial = false;
      this.showAssignment2 = false;
      this.showSortingConclusion = false;
    },
    startIntroduction() {
      this.hideAllComponents();
      this.showIntroduction = true;
    },
    startIntroduction2() {
      this.hideAllComponents();
      this.showIntroduction2 = true;
    },
    startAssignment1Tutorial() {
      this.hideAllComponents();
      this.showAssignment1Tutorial = true;
    },
    startAssignment1() {
      this.hideAllComponents();
      this.showAssignment1 = true;
    },
    startAssignment2Tutorial() {
      this.hideAllComponents();
      this.showAssignment2Tutorial = true;
    },
    startAssignment2() {
      this.hideAllComponents();
      this.showAssignment2 = true;
    },
    startSortingConclusion() {
      this.hideAllComponents();
      this.showSortingConclusion = true;
    },
    async finishSorting() {
      // get my informatik OSA for current user 
      const myOSA = await myOSAhandler.getMyInformatikOSA(this.$store.getters.getUser.username);
      //set sortingFinished=true in backend
      await myOSAhandler.finishModule(myOSA.id, { sortingFinished: true })
      this.$router.push("/informatik");
    },
    async getIsSortingCompleted(){
      const myOSA = await myOSAhandler.getMyInformatikOSA(this.$store.getters.getUser.username);
      return myOSA.sortingFinished;
    }
  },
};
</script>

<style scoped>
.error-message{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
</style>
