<template>
  <div class="container mb-2 pb-2">
    <div class="button-bar assignment-bar">
      <!-- Button trigger modal -->
      <div class="button-bar-left">
        <div
          v-if="showHelpBtn"
          type="button"
          @click="openHelpModal"
          class="custom-btn-secondary dismiss-btn"
          data-bs-toggle="modal"
          data-bs-target="#modal"
        >
          <i class="bi bi-question-circle" style="padding-right: 5px;"></i>Hilfe
        </div>
      </div>
      <div class="button-bar-right">
        <div
          v-if="showDismissBtn"
          type="button"
          @click="openQuitModal"
          class="custom-btn-secondary dismiss-btn"
          data-bs-toggle="modal"
          data-bs-target="#modal"
        >
        „Sortieren mit System“ abbrechen
        </div>
      </div>
    </div>

    <BaseModal>
      <template v-slot:header>
        <div class="modal-title">
          <!-- help modal title -->
          <div v-if="showModal === 'help'">Vorgaben und Regeln</div>
          <!-- quit modal title -->
          <div v-else-if="showModal === 'quit'">„Sortieren mit System“ abbrechen?</div>
          <div v-else>Error</div>
        </div>
      </template>

      <template v-slot:body>
        <!-- help modal body -->
        <div v-if="showModal === 'help'">
          <p>Und hier noch einmal alle Vorgaben und Regeln in Kürze:</p>
          <ul>
            <li>
              Die Karten sollen nach der Größe ihres Wertes sortiert werden -
              links der kleinste, rechts der größte Wert.
            </li>
            <li>
              Der Lesezeiger macht den Wert einer verdeckten Karte sichtbar.
            </li>
            <li>
              Die Werte verdeckter Karten sind unbekannt, auch wenn sie schon
              einmal gelesen wurden.
            </li>
            <li>Der Merkzeiger hält eine Karte sichtbar.</li>
            <li>Die Karten werden durch Drag &#38; Drop getauscht.</li>
            <li>
              Mindestens eine Karte muss nach einem Tausch auf ihrem endgültigen
              Platz liegen.
            </li>
            <li>Die sortierten Karten werden zum Abschluss fixiert.</li>
            <li>
              Die Sortierung soll in möglichst wenigen Schritten erfolgen.
            </li>
          </ul>
        </div>
        <!-- quit modal body -->
        <div v-else-if="showModal === 'quit'">
          <p>
            Wollen Sie das Modul „Sortieren mit System“ wirklich abbrechen? Bitte beachten Sie, dass Ihre bisherigen Fortschritte nicht gespeichert werden und 
            Sie keine Rückmeldung zu Ihrer aktuellen Bearbeitung der Aufgaben bekommen, wenn Sie jetzt abbrechen.
          </p>
          <p>
            Falls Sie dennoch abbrechen möchten, teilen Sie uns bitte Ihren
            Grund mit:
          </p>
          <form>
            <div class="form-group">
              <textarea class="form-control" id="message-text"></textarea>
            </div>
          </form>
        </div>
        <div v-else>Error loading text</div>
      </template>

      <template v-slot:footer>
        <div v-if="showModal === 'help'">
          <button type="button" class="btn custom-btn" data-bs-dismiss="modal">
            OK
          </button>
        </div>
        <!-- quit modal -->
        <div v-else-if="showModal === 'quit'">
          <button type="button" class="btn custom-btn-secondary" data-bs-dismiss="modal">
            Nein, Modul fortsetzen
          </button>
          <button
            type="button"
            class="btn custom-btn-danger"
            data-bs-dismiss="modal"
            @click="endAssignment"
          >
            Ja, Modul abbrechen
          </button>

        </div>
        <div v-else>
          <button type="button" class="btn custom-btn" data-bs-dismiss="modal">
            Schließen
          </button>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import BaseModal from "@/components/BaseModal.vue";

export default {
  name: "AssignmentbarSorting1",
  components: {
    BaseModal,
  },
  data() {
    return {
      showModal: "",
    };
  },
  props: {
    showHelpBtn: Boolean,
    showDismissBtn: Boolean,
  },
  emits: ["end-assignment"],
  methods: {
    openHelpModal() {
      this.showModal = "help";
    },
    openQuitModal() {
      this.showModal = "quit";
    },
    endAssignment() {
      let messageText = document.getElementById("message-text").value;
      if (messageText.length > 1) {
        this.saveQuitMessage(messageText);
      }

      this.$emit("end-assignment");
      this.$router.push("/informatik");
    },
    async saveQuitMessage(messageText) {
      try {
        await fetch(
          process.env.VUE_APP_STRAPI_API_URL +
            "/api/inf-sorting-quit-messages", //TODO: make url dynamic
          {
            method: "POST",
            mode: "cors",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${window.localStorage.getItem("jwt")}`,
            },
            body: JSON.stringify({
              data: {
                message: messageText,
              },
            }),
          }
        );
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>

<style scoped>
</style>
