<template>
  <div class="tutorial-container">
    <div class="card-container">
      <CardsTutorial
        :tutorialStep="pageNumber"
        @fix-tutorial-finished="fixTutorialFinished"
        @read-tutorial-finished="readTutorialFinished"
        @save-tutorial-finished="saveTutorialFinished"
        @swap-tutorial-finished="swapTutorialFinished"
      ></CardsTutorial>
    </div>

    <div class="slides-container">
      <div class="slide" v-if="pageNumber === 1">
        <div class="slide-left-container"></div>
        <div class="slide-text-container">
          <div class="animate glow main-text">
            <p>
              Die <strong>Werte</strong> aller Karten sind während der
              Bearbeitung der Aufgabe grundsätzlich <strong>verdeckt</strong>.
            </p>
          </div>
          <div class="animate glow additional-text">
            Diese Ausgangssituation ist kennzeichnend für ein Computerprogramm,
            für das die Werte zu Beginn nicht bekannt sind und welches Hilfsmittel
            benötigt, um diese verarbeiten zu können.
          </div>
        </div>
      </div>

      <div class="slide" v-else-if="pageNumber === 2">
        <div class="slide-left-container"></div>
        <div class="slide-text-container">
          <div class="main-text">
            <p>
              Für das Sortieren der Karten stehen Ihnen deshalb verschiedene
              <strong>Hilfsmittel</strong> zur Verfügung.
            </p>
          </div>
          <div class="additional-text"></div>
        </div>
      </div>
      <div class="slide" v-else-if="pageNumber === 3">
        <div class="slide-left-container">
          <h3 class="slide-left-container-header">LESEN</h3>
          <div class="slide-left-container-content">
            <div v-if="!lesenActive">
              <i
                class="bi bi-eye"
                style="font-size: 5em"
                @click="lesenActive = !lesenActive"
              ></i>
            </div>
            <div v-else>
              <i
                class="bi bi-eye"
                style="color: orange; font-size: 5em"
                @click="lesenActive = !lesenActive"
              ></i>
            </div>
            <ArrowIcon
              class="arrow"
              :color="lesenActive ? 'orange' : 'invisible'"
            />
          </div>
        </div>
        <div class="slide-text-container">
          <div class="main-text">
            <p>
              Das Hilfsmittel <strong>Lesen</strong> verwenden Sie, indem Sie
              auf das (Augen-)Symbol unterhalb einer Karte klicken. Sie richten
              damit den Lesezeiger auf die Karte und können deren Wert lesen.
              Probieren Sie es aus, indem Sie auf das Auge unter verschiedenen
              Karten klicken.
            </p>
          </div>
          <div class="additional-text">
            <p>
              Die meisten Menschen würden wahrscheinlich zuerst alle Karten
              umdrehen, um sich einen Überblick zu verschaffen. Ein
              Computerprogramm kann das jedoch nicht. Deswegen wird in dieser
              Aufgabe ein Lesezeiger eingesetzt, der die Vorgehensweise eines
              Computerprogrammes beim Lesen einer Karte simuliert.
            </p>
          </div>
          <div class="error-text" v-html="errorMessage"></div>
        </div>
      </div>

      <div class="slide" v-else-if="pageNumber === 4">
        <div class="slide-left-container"></div>
        <div class="slide-text-container">
          <div class="main-text">
            Bedenken Sie, dass die Werte verdeckter Karten für ein
            Computerprogramm unbekannt sind, auch wenn sie schon mal gelesen
            wurden.
          </div>
          <div class="additional-text">
            Das "Vergessen" der Zahlenwerte bereits gesehener Karten ist für uns
            Menschen schwer nachvollziehbar, da wir uns solche Werte spontan
            merken und nicht vergessen.
          </div>
        </div>
      </div>

      <div class="slide" v-else-if="pageNumber === 5">
        <div class="slide-left-container">
          <h3 class="slide-left-container-header">MERKEN</h3>
          <div class="slide-left-container-content">
            <div v-if="!merkenActive">
              <i
                class="bi bi-pin-angle"
                style="font-size: 5em"
                @click="merkenActive = !merkenActive"
              ></i>
            </div>
            <div v-else>
              <i
                class="bi bi-pin-angle-fill"
                style="color: #1ea983; font-size: 5em"
                @click="merkenActive = !merkenActive"
              ></i>
            </div>
            <ArrowIcon
              class="arrow"
              :color="merkenActive ? 'green' : 'invisible'"
            />
          </div>
        </div>
        <div class="slide-text-container">
          <div class="main-text">
            Mit einem Klick auf das Hilfsmittel <strong>Merken</strong> können
            Sie den Wert einer gelesenen Karte sichtbar halten, auch wenn der
            Lesezeiger nicht mehr auf diese Karte gerichtet ist. Wird der
            Merkzeiger auf eine andere Karte gesetzt, steht der vorherige Wert nicht mehr
            zur Verfügung. Setzen Sie nun den Merkzeiger auf die Karte, deren
            Wert Sie sehen und stellen Sie anschließend den Lesezeiger auf eine
            andere Karte. Wie Sie sehen, können Sie durch die Kombination der
            Hilfsmittel <strong>Lesen</strong> und <strong>Merken</strong> die
            Werte zweier Karten sichtbar machen.
          </div>
          <div class="additional-text">
            Wir Menschen merken uns viele Dinge - ob wir wollen oder nicht. Ein
            Computerprogramm „merkt“ sich nur Dinge, die ihm explizit vorgeben
            werden. Daher sind die Werte verdeckter Karten für ein
            Computerprogramm immer unbekannt. Für den Vergleich von Zahlenwerten
            werden jedoch zwei „sichtbare“ Karten benötigt. Mit Hilfe des
            Merkzeigers ist es jetzt möglich, zwei Kartenwerte gleichzeitig
            betrachten zu können.
          </div>
          <div class="error-text" v-html="errorMessage"></div>
        </div>
      </div>

      <div class="slide" v-else-if="pageNumber === 6">
        <div class="slide-left-container">
          <h3 class="slide-left-container-header">TAUSCHEN</h3>
          <div class="slide-left-container-content">
            <BaseEmptyCard
              :showSwapConfirmation="true"
              :showBtnContainer="false"
            />
          </div>
        </div>
        <div class="slide-text-container">
          <div class="main-text">
            Um die Reihenfolge der Karten zu ändern, tauschen Sie jeweils zwei
            Karten durch Klicken und Ziehen mit der Maus (Drag &#38; Drop).
            Versuchen Sie es! Klicken Sie auf eine der beiden Karten, halten Sie
            die Maustaste gedrückt und ziehen Sie die Karte auf die Andere. Den
            Tausch schließen Sie ab, indem Sie auf das grüne Feld mit dem
            Häckchen klicken. Der Klick auf das rote Feld mit „X“ bricht den
            Tausch ab.
          </div>
          <div class="additional-text"></div>
          <div class="error-text" v-html="errorMessage"></div>
        </div>
      </div>

      <div class="slide" v-else-if="pageNumber === 7">
        <div class="slide-left-container"></div>

        <div class="slide-text-container">
          <div class="main-text">
            <p>Aber beachten Sie:</p>
            <p></p>
            <p>
              Werden zwei Karten in ihrer Position verändert (= Sortierschritt),
              muss zumindest eine der beiden Karten nach Abschluss der
              Tauschoperation an ihrem endgültigen Platz zu liegen kommen.
            </p>
          </div>
          <div class="additional-text"></div>
        </div>
      </div>
      <div class="slide" v-else-if="pageNumber === 8">
        <div class="slide-left-container">
          <h3 class="slide-left-container-header">FIXIEREN</h3>
          <div class="slide-left-container-content">
            <BaseEmptyCard
              :showSwapConfirmation="false"
              :showBtnContainer="false"
            />
          </div>
        </div>

        <div class="slide-text-container">
          <div class="main-text">
            <strong>Und zuletzt:</strong> Mit dem
            <strong>Hilfsmittel „Fixieren“</strong> grenzen Sie den Bereich
            bereits sortierter Karten vom Bereich noch zu sortierender Karten
            ab. Klicken Sie dazu auf das Wort „FIX“ auf der jeweiligen Karte.
            Mit jeder sortierten Karte muss der Fixierbereich erweitert werden.
            Im fixierten Bereich sind dann auch keine Operationen mehr möglich.
            Versuchen Sie, die Aufgabe möglichst effizient, d. h. mit wenigen
            Schritten zu lösen.
          </div>
          <div class="additional-text">
            Damit das Programm nicht „vergisst“, welche Zahlen schon sortiert
            sind und wo noch zu sortieren ist, verwendet es die Fixierfunktion.
          </div>
          <div style="font-weight: bold">
            Um die Anleitung zu beenden, fixieren Sie in diesem Schritt
            zumindest zwei Karten.
          </div>
          <div
            class="error-text"
            v-if="true ? '' : startAssignmentClicked && !fixFinished"
          >
            Um das Tutorial abzuschließen, fixieren Sie in diesen Schritt
            zumindest zwei Karten.
          </div>
           <!--<div
            class="slide-btn-container"
            style="
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              border: 1px solid black;
              align-self: flex-end;
            "
          >
          <div
            class="custom-btn"
            v-if="showStartAssignmentBtn"
            @click="$emit('start-assignment')"
          >
            Aufgabe starten
          </div></div> -->
        </div>
      </div>
    </div>

    <div class="navigation-container">
      <div class="navigation">
        <div class="prev" @click="previousPage">&#10094;</div>
        <span class="page-number">{{ pageNumber }}</span>
        <span v-for="i in pages" :key="i"
          ><div class="dot" :class="{ 'active-dot': pageNumber >= i }"></div
        ></span>
        <span class="page-number">8</span>
        <div
          class="next"
          @click="nextPage"
        >
          &#10095;
        </div>
      </div>
      <!--       <div @click="nextPage" class="start-btn custom-btn" v-if="pageNumber == 8">
        Aufgabe starten
      </div> -->
    </div>
  </div>
</template>

<script>
import CardsTutorial from "@/components/inf/sorting/Assignment1/Tutorial/CardsTutorial.vue";
import BaseEmptyCard from "@/components/inf/sorting/Assignment1/Tutorial/BaseEmptyCard.vue";
import ArrowIcon from "@/components/icons/ArrowIcon.vue";

export default {
  components: {
    BaseEmptyCard,
    CardsTutorial,
    ArrowIcon,
  },
  props: {
    startAssignmentClicked: Boolean,
  },
  emits: ["next-page-clicked", "tutorial-finished", "start-assignment"],
  data() {
    return {
      pageNumber: 1,
      pages: 8,
      readFinished: false,
      saveFinished: false,
      fixFinished: false,
      swapFinished: false,
      errorMessage: "",
      lesenActive: false,
      merkenActive: false,
      showStartAssignmentBtn: false,
    };
  },
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  methods: {
    nextPage() {
      this.$emit("next-page-clicked");
      //check if read tutorial is finished
      if (this.pageNumber == 3 && !this.readFinished) {
        //set error message
        this.errorMessage =
          "Klicken Sie nacheinander auf das Auge unter den beiden Karten. Sie können fortsetzen, wenn Sie beide Karten gelesen haben!";
        return;
      }
      //check if save tutorial is finished
      if (this.pageNumber == 5 && !this.saveFinished) {
        //show error message
        this.errorMessage =
          "Um zur nächsten Seite zu gelangen, machen Sie zwei Karten sichtbar und klicken Sie dann auf den blauen Pfeil unten <em>rechts</em>.";
        return;
      }
      if (this.pageNumber == 6 && !this.swapFinished) {
        //show error message
        this.errorMessage =
          "Um zur nächsten Seite zu gelangen, nehmen Sie zumindest einen Tausch vor und klicken Sie dann auf den blauen Pfeil unten <em>rechts</em>.";
        return;
      }
      this.pageNumber++;
      this.pageNumber = Math.min(this.pages, this.pageNumber);
      this.errorMessage = "";
    },
    previousPage() {
      this.pageNumber--;
      this.pageNumber = Math.max(1, this.pageNumber);
    },
    readTutorialFinished() {
      this.readFinished = true;
    },
    saveTutorialFinished() {
      this.saveFinished = true;
    },
    fixTutorialFinished() {
      this.fixFinished = true;
      this.showStartAssignmentBtn = true;
      this.$emit("tutorial-finished");
    },
    swapTutorialFinished() {
      this.swapFinished = true;
    },
  },
};
</script>

<style scoped>
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}

.slide {
  display: flex;
  flex-flow: row nowrap;
  min-height: 300px;
}

.slide-text-container {
  border: 1px solid black;
  border-radius: 10px;
  min-width: 80%;
  max-width: 80%;
  min-height: 350px;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.slide-left-container {
  border: 1px solid black;
  border-radius: 10px;
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slide-left-container-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.additional-text {
  padding-top: 10px;
  font-style: italic;
  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;
}

.error-text {
  padding-top: 10px;
  font-weight: bold;
  color: red;
}

/* ----- NAVIGATION ------ */
.navigation-container {
  position: relative;
}

.navigation {
  flex: 0 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.prev,
.next {
  cursor: pointer;
  width: auto;
  padding: 25px;
  font-weight: bolder;
  font-size: 2.2em;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  color: #006bac;
}
.hidenext {
  visibility: hidden;
}

/* The dots/bullets/indicators */
.dot {
  height: 6px;
  width: 15px;
  margin: 0 3px;
  background-color: #006bac;
  opacity: 0.3;
  transition: background-color 0.6s ease;
}

.active-dot {
  background-color: #006bac;
  opacity: 1;
}

.page-number {
  font-size: 1.2em;
  margin: 10px;
}

.start-btn {
  flex: 0 1 auto;
  margin-left: auto;
}

.instruction {
  font-weight: bold;
}
i {
  cursor: pointer;
}
.arrow {
  height: 5em;
}
</style>
