<template>
  <div class="container">
    <AssignmentbarSorting1
      :showHelpBtn="false"
      :showDismissBtn="true"
      :showFinishBtn="false"
    ></AssignmentbarSorting1>
    <div class="animate glow row">
      <h2>Nun zum zweiten Teil der Kartensortieraufgabe</h2>
      <p>
        Im vorigen Teil der Aufgabe haben Sie unter Verwendung verschiedener
        Hilfsmittel Karten in aufsteigender Reihenfolge sortiert. Wahrscheinlich
        haben Sie dabei bemerkt, dass Sie einem gewissen Schema gefolgt sind.
        Sie haben stets die Karte mit der größten oder kleinsten Zahl unter den
        noch nicht fixierten Zahlen gesucht und diese dann mit jener getauscht,
        die ganz links oder rechts im noch nicht fixierten Bereich lag. Das
        Schema, dem Sie hier gefolgt sind, umfasst eine
        <strong
          >Folge von Handlungsanweisungen, die in der Informatik als Algorithmus
          bezeichnet wird</strong
        >. Die nächste Aufgabe besteht nun darin, dieses Schema als Algorithmus nachvollziehbar
        darzustellen.
      </p>
    </div>
   <!-- <div class="btn-container-right">
      <div class="custom-btn" @click="showPart2Clicked()">Weiter</div>
    </div>
     HANDLUNGSANWEISUNGEN CONTAINER -->
    <div class="text-highlight-area" style="display: none">
      <p>
        <strong
          >Es stehen Ihnen dazu verschiedene Handlungsanweisungen zur
          Verfügung:</strong
        >
      </p>
      <div class="todo-text-marker">
        REIHENFOLGE VON ANWEISUNGEN WIRD BEREITS GEZEIGT; ANWEISUNGEN HIER NICHT
        ZEIGEN? ERST BEI AUFGABE ZEIGEN?
      </div>
      <ul>
        <li>
          Setze Lesezeiger auf die ganz links/rechts befindliche nichtfixierte
          Karte
        </li>
        <li>
          Setze Merkzeiger auf die ganz links/rechts befindliche nichtfixierte
          Karte
        </li>
        <li>Setze den Lesezeiger eine Position nach rechts/links</li>
        <li>
          Wenn die Zahl beim Lesezeiger kleiner ist als die Zahl beim
          Merkzeiger, dann setze den Merkzeiger auf die Position des
          Lesezeigers.
        </li>
        <li>
          Wenn der Lesezeiger nicht auf der ganz links/rechts befindlichen Karte
          liegt, springe zu Schritt (3.)
        </li>
        <li>
          Vertausche die Karte unter dem Merkzeiger mit der ganz links/rechts
          befindlichen nichtfixierten Karte.
        </li>
        <li>Fixiere die ganz links/rechts befindliche nichtfixierte Karte.</li>
        <li>So lange es nichtfixierte Karten gibt, springe zu Schritt (1.)</li>
      </ul>
    </div>
    <div class="animate glow part2-container" v-if="true">
      <h3>Ihre Aufgabe</h3>
      <div class="instructions-container">
        <div
          class="instruction-section instruction-page1"
          v-if="pageNumber === 1"
        >
          <p class="animate glow tutorial-text">
            <!--Drag&Drop-->
            In diesem Teil der Aufgabe sollen Sie Handlungsanweisungen zu einem
            Algorithmus zusammenstellen. Dafür legen Sie die
            <strong> Kärtchen mit den Handlungsanweisungen </strong> auf den
            entsprechenden Ablageflächen ab.
          </p>
          <img
            :src="dragAndDropTutorial"
            class="card-img-top tutorial-img"
            alt="Animation von Handlungsanweisung"
          />
          <!--<video width="600" height="340" controls>
  <source :src="dragAndDropTutorial" type="video/mp4">
Your browser does not support the video tag.
</video> -->
        </div>
        <div
          class="instruction-section instruction-page2"
          v-else-if="pageNumber === 2"
        >
          <p class="animate glow tutorial-text">
            <!--AUSWAHL-->
            Ein Algorithmus besteht im Wesentlichen aus einer Abfolge von
            logischen Handlungen und Operationen, welche beim Ausführen
            abgearbeitet werden. Für manche Kärtchen ist es noch notwendig, dass
            Sie solche logische Operationen einer
            <strong> Handlungsanweisung noch vervollständigen. </strong>.
          </p>
          <img
            :src="selectTutorial"
            class="card-img-top tutorial-img"
            alt="Animation für Vervollständigen von Handlungsanweisungen"
          />
        </div>

        <div
          class="instruction-section instruction-page3"
          v-else-if="pageNumber === 3"
        >
          <p class="animate glow tutorial-text">
            <!--Bedienfeld Play Pause Stop -->
            Der Computer/das Programm durchläuft nach dem Start die
            Handlungsanweisungen des Algorithmus von oben nach unten. Sobald
            eine Handlungsanweisung/Zeile ausgeführt wurde, wird mit der
            nächsten Zeile fortgesetzt. Mit dem
            <strong>Start- und dem Pause-Button</strong> können Sie das Programm
            jederzeit pausieren und fortsetzen. <br />Der
            <strong>Stopp-Button</strong> verwirft alle bisher durchgeführten
            Schritte. Danach können Sie einen angepassten Algorithmus neu starten.
          </p>
          <img
            :src="playPauseStopTutorial"
            class="card-img-top tutorial-img"
            alt="Animation zur Bedienung des Algorithmus"
          />
        </div>

        <div
          class="instruction-section instruction-page4"
          v-else-if="pageNumber === 4"
        >
          <p class="animate glow tutorial-text">
            <!--Bedienfeld Weiter-->
            Bei der Erstellung eines Algorithmus ist es oft notwendig, einzelne Schritte manuell durchzuführen. Dadurch können Fehler einfacher
            gefunden und die Richtigkeit der Anweisungen überprüft werden. Mit
            dem
            <strong>Weiter-Button</strong> führen Sie nur die nächste
            Handlungsanweisung aus und können das Programm somit manuell
            bedienen. Die Handlungsanweisung, die als nächstes durchgeführt
            wird, ist mit einem blauen Rahmen gekennzeichnet.
          </p>
          <img
            :src="continueTutorial"
            class="card-img-top tutorial-img"
            alt="Animation zur Bedienung des Weiter-Button"
          />
        </div>

        <div
          class="instruction-section instruction-page5"
          v-else-if="pageNumber === 5"
        >
          <p class="animate glow tutorial-text">
            <!--Sprung in Zeile-->
            <strong>Aber Achtung:</strong> Es gibt auch Handlungsanweisungen,
            die das Programm in andere Zeilen springen lassen - dann wird nicht
            in der nächsten Zeile, sondern in der angegebenen Zeile fortgesetzt.<br/>
          Versuchen Sie nun, die Handlungsanweisungen in der richtigen
          Reihenfolge auf die Ablageflächen zu ziehen, um so die Zahlenliste
          aufsteigend zu sortieren!
        </p>
          <img
            :src="jumpTutorial"
            class="card-img-top tutorial-img"
            alt="Animation zur Darstellung von Sprüngen im Algorithmus"
          />
        </div>

        <div class="navigation-container">
          <div class="navigation">
            <div class="prev" @click="previousPage">&#10094;</div>
            <span class="page-number">{{ pageNumber }}</span>
            <span v-for="i in pages" :key="i"
              ><div class="dot" :class="{ 'active-dot': pageNumber >= i }"></div
            ></span>
            <span class="page-number">{{ pages }}</span>
            <div class="next" @click="nextPage">&#10095;</div>
          </div>
        </div>
      </div>

      <div class="start-text-container">

        <div class="btn-container-right">
          <div class="custom-btn" 
          :class="!showStart ? 'custom-btn-disabled' : ''" @click="endAssignment2Tutorial()">
            Aufgabe starten
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AssignmentbarSorting1 from "@/components/inf/sorting/Assignment1/AssignmentbarSorting1.vue";
import * as scrolling from "@/helpers/scroll-helper.js";

export default {
  components: {
    AssignmentbarSorting1,
  },
  emits: ["assignment2-tutorial-finished"],
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    });
  },
  data() {
    return {
      pageNumber: 1,
      pages: 5,
      showStart: false,
      showPart2: false,
      //images / videos
      //dragAndDropTutorial: require("@/assets/inf/sorting/drag_drop_tut_assign2.mp4"),
      dragAndDropTutorial: require("@/assets/inf/sorting/1_drag_drop_tutorial.gif"),
      selectTutorial: require("@/assets/inf/sorting/2_select_tutorial.gif"),
      playPauseStopTutorial: require("@/assets/inf/sorting/3_play_pause_stop_tutorial.gif"),
      continueTutorial: require("@/assets/inf/sorting/4_continue_tutorial.gif"),
      jumpTutorial: require("@/assets/inf/sorting/5_jump_tutorial.gif"),
    };
  },
  methods: {
    async showPart2Clicked() {
      this.showPart2 = true;
      await this.$nextTick();
      setTimeout(function () {
        scrolling.scrollToElementByClassName("part2-container", {
          behavior: "smooth",
        });
      }, 80);
    },
    nextPage() {
      this.pageNumber++;
      this.pageNumber = Math.min(this.pages, this.pageNumber);
      if (this.pageNumber == 5) {
        this.showStart = true;
        setTimeout(function () {
          scrolling.scrollToElementByClassName("start-text-container", {
            behavior: "smooth",
          });
        }, 80);
      }
    },
    previousPage() {
      this.pageNumber--;
      this.pageNumber = Math.max(1, this.pageNumber);
    },
    endAssignment2Tutorial() {
      if(this.showStart){
      this.$emit("assignment2-tutorial-finished");
      }
    },
  },
};
</script>

<style scoped>
p{
  line-height: 1.3;
}
.btn-container-right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-self: flex-end;
  margin: 20px;
}
.instruction-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  min-height: 300px;
}

li {
  list-style-type: none;
  padding: 10px 10px;
  transition: all 0.3s ease;
  border: 2px solid black;
  margin: 5px;
}

.bi-image {
  font-size: 15em;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tutorial-text{
  min-height: 120px;
}

.tutorial-img {
  width: 80%;
  border: 2px solid black;
  margin: 20px;
}

/* ----- NAVIGATION ------ */
.navigation-container {
  position: relative;
}

.navigation {
  flex: 0 1 auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.prev,
.next {
  cursor: pointer;
  width: auto;
  padding: 25px;
  font-weight: bolder;
  font-size: 2.2em;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  color: #006bac;
}
.hidenext {
  visibility: hidden;
}
/* The dots/bullets/indicators */
.dot {
  height: 6px;
  width: 15px;
  margin: 0 3px;
  background-color: #006bac;
  opacity: 0.3;
  transition: background-color 0.6s ease;
}

.active-dot {
  background-color: #006bac;
  opacity: 1;
}

.page-number {
  font-size: 1.2em;
  margin: 10px;
}
@keyframes animate-glow {
  0% {
    opacity: 0;
    filter: brightness(3) saturate(3);
    transform: scale(0.8, 0.8);
  }
  100% {
    opacity: 1;
    filter: brightness(1) saturate(1);
    transform: scale(1, 1);
  }
}
.animate {
  animation-duration: 0.75s;
  animation-delay: 0.2s;
  animation-name: animate-fade;
  animation-timing-function: cubic-bezier(0.26, 0.53, 0.74, 1.48);
  animation-fill-mode: backwards;
}

/* Glow In */
.animate.glow {
  animation-name: animate-glow;
  animation-timing-function: ease;
}
</style>
