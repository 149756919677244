<template>
  <div class="bubble-vis-container">
    <div id="buttons">
      <button @click="bubbleSort">Bubble Sort</button>
      <button @click="stop = true" style="margin-left:40px">Stop</button>
      <button @click="reset">Reset</button>
    </div>

    <div id="bubble-vis"></div>

    <div>Steps: <span id="bubble-counter">0</span></div>
  </div>
</template>

<script>
import * as d3 from 'd3';

export default {
  data() {
    return {
      durationTime: 0,

      array: [],
      unsortedArray: [],
      sortedArray: [],
      count: 0,

      stop: false,
      steps: 0,

      margin: { top: 40, right: 0, bottom: 0, left: 0 },
      width: 0,
      height: 0,
      barWidth: 0,

      x: null,
      svg: null,
      rects: null,
      labels: null
    }
  },


  props: ['n' , 'stepDuration'],

  mounted() {
    this.count = Number(this.n) + 1;
    this.array = d3.shuffle(d3.range(1, this.count))
    this.durationTime = Number(this.stepDuration);
    this.unsortedArray = [...this.array];
    this.sortedArray = [];


    this.width = 700 - this.margin.left - this.margin.right;
    this.height = 300 - this.margin.top - this.margin.bottom;
    this.barWidth = this.width / this.count;

    this.x = d3.scaleLinear()
      .domain([0, this.count])
      .range([0, this.width]);

    this.svg = d3.select("#bubble-vis").append("svg")
      .attr("width", this.width + this.margin.left + this.margin.right)
      .attr("height", this.height + this.margin.top + this.margin.bottom)
      .append("g")
      .attr("transform", "translate(" + this.margin.left + "," + this.margin.top + ")");

    this.rects = this.svg.append("g")
      .attr("transform", "translate(" + this.barWidth + ",2)")
      .selectAll("rect")
      .data(this.unsortedArray)
      .enter().append("rect");

    this.labels = this.svg.selectAll("text")
      .data(this.unsortedArray)
      .enter().append("text");


    this.labels.attr("id", function (d) { return "bubble-text" + d })
      .attr('x', (d, i) => { return this.x(i) })
      .attr('y', () => { return 0 })
      .html(function (d) { return d; });

    this.rects.attr("id", function (d) { return "bubble-rect" + d })
      .attr("width", this.barWidth * .9)
      .attr("height", (d) => { return (d * this.barWidth / 3) })
      .attr('x', (d, i) => { return this.x(i) - this.barWidth })
      .attr('y', (d) => { return this.height - (d * this.barWidth / 3) })
  },


  methods: {
    async reset() {
      this.stop = true;

      await d3.timeout(() => {
        // Reset arrays
        this.unsortedArray = [...this.array];
        this.sortedArray = [];
        this.stop = false;

        d3.select("#bubble-counter").html(this.steps = 0) // Reset step counter

        this.labels.attr("class", "")
          .classed("testing", false)
          .classed("sorted", false)
          .transition().duration(2000)
          .attr("x", (d, i) => { return this.x(i); })

        this.rects.attr("class", "")
          .transition().duration(2000)
          .attr("x", (d, i) => { return this.x(i - 1); })

      }, this.durationTime * 2);


    },


    bubbleSort() {

      function sortPass(i, that) {
        if (!that.unsortedArray.length || that.stop) return that.stop = false

        if (i <= that.unsortedArray.length) {
          if (that.unsortedArray[i] < that.unsortedArray[i - 1]) {

            d3.select("#bubble-rect" + that.unsortedArray[i]).attr("class", "testing")
            d3.select("#bubble-rect" + that.unsortedArray[i - 1]).attr("class", "testing")

            d3.timeout(function () {
              d3.select("#bubble-rect" + that.unsortedArray[i]).attr("class", "")
              d3.select("#bubble-rect" + that.unsortedArray[i - 1]).attr("class", "")
            }, that.durationTime);

            var temp = that.unsortedArray[i - 1];
            that.unsortedArray[i - 1] = that.unsortedArray[i];
            that.unsortedArray[i] = temp;

            that.slide(that.unsortedArray[i], i + that.sortedArray);
            that.slide(that.unsortedArray[i - 1], i - 1 + that.sortedArray);

            d3.select("#bubble-counter").html(++that.steps);

            d3.timeout(function () { return sortPass(++i, that) }, that.durationTime);

          } else if (i == that.unsortedArray.length) {

            for (let n = i; n == that.unsortedArray[n - 1]; n--) {
              d3.select("#bubble-text" + n).attr("class", "sorted")
              d3.select("#bubble-rect" + n).attr("class", "sorted")
              that.unsortedArray.pop();
            }

            sortPass(++i, that);
          } else {
            sortPass(++i, that);
          }

        } else {
          that.bubbleSort();
        }
      }
      sortPass(1, this);
    },

    // Slides the text and rect of d to the position of index i 
    slide(d, i) {
      d3.select("#bubble-text" + d)
        .transition().duration(this.durationTime)
        .attr("x", () => { return this.x(i); })

      d3.select("#bubble-rect" + d)
        .transition().duration(this.durationTime)
        .attr("x", () => { return this.x(i - 1); })
    }


  }

}
</script>


<style scoped>

#buttons > button {
  background-color: darkgreen;
  border-radius: 5px;
  color: white;
  margin-right: 10px;
}

.bubble-vis-container {
  margin: 20px;
  margin-left: 20px;
}

#bubble-vis {
  margin: 10px 0;
}

#bubble-vis :deep(text) {
  fill: black;
}

#bubble-vis :deep(rect) {
  fill: darkgreen;
}

#bubble-vis :deep(.sorted) {
  fill: #999;
}

#bubble-vis :deep(.min) {
  fill: red;
}

#bubble-vis :deep(.testing) {
  fill: orange;
}
</style>