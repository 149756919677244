<template>
  <div class="feedback-container">
    <div class="feedback-field">
      <p>{{ feedbackMessage }}</p>
    </div>
    <div class="button-container">
      <div class="custom-btn" v-if="!lastActionValid" @click="undoLastAction">OK</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    feedbackMessage: String,
    lastActionValid: Boolean,
  },
  emits: ["undo-clicked", "finish-assignment"],
  data() {
    return {};
  },
  methods: {
    undoLastAction() {
      this.$emit("undo-clicked");
    },
    finishAssignment() {
      this.$emit("finish-assignment");
    },
  },
};
</script>

<style scoped>

.feedback-container{
  margin-top: 40px;
  border: 1px solid black;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 40px;
}

.feedback-field {
  height: 100px;
  width: 100%;
}

.button-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
</style>
